import getTextFromToken from "./tokenised-text"

const ifMultiple = (n: number, x: any) => (n > 1 ? x : '')
const ss = (n?: number) => ifMultiple(n || 0, 's')

const DICTIONARY: { [key: string]: (number?: number) => string } = {
  RANK_IS_NOT_UNIQUE: (n) =>
    `${n} candidates with tied rank${ss(n)}. Each candidate must have a unique rank within the grade.`,
  RANK_IS_TOO_LOW: (n = 1) =>
    `${n} ${n > 1 ? 'ranks are' : 'rank is'} too low.`,
  RANK_IS_TOO_HIGH: (n = 1) =>
    `${n} ${n > 1 ? 'ranks are' : 'rank is'} too high.`,
  RANK_MUST_BE_NUMBER: (n = 1) =>
    `${n} ${n > 1 ? 'ranks are' : 'rank is'} incorrect. Rank must be a number.`,
  RANK_IS_NOT_REQUIRED: (n) => `${n} rank${ss(n)} not required.`,
  GRADE_IS_INVALID_FOR_CANDIDATE: (n) => `${n} grade${ss(n)} are not valid for this ${getTextFromToken("syllabus")}`,
  GRADE_IS_EMPTY: (n) => `${n} missing grade${ss(n)}`,
  DATA_CANNOT_BE_CHANGED: (n) =>
    `${n} candidate${ss(n)} details changed. Please check.`,
  DATA_WAS_NOT_EXPECTED: (n = 1) =>
    `${n} ${
      n > 1 ? 'entries were' : 'entry was'
    } not expected in this template.`,
  REQUIRED_FIELD: () => 'Missing data from required field(s)',
  INVALID_FILE_FORMAT: () =>
    'You have uploaded an invalid file format. Please upload as a CSV file.',
  WRONG_CENTRE_ID: () => 'Centre ID is incorrect.',
  WRONG_SYLLABUS_ID: () => `The ${getTextFromToken("syllabus")} ID is incorrect.`,
  SYLLABUS_CANNOT_BE_EDITED: () => `This ${getTextFromToken("syllabus")} has already been submitted`,
}

const getTextFromError = (key: string, number?: number): string =>
  DICTIONARY[key] ? DICTIONARY[key](number) : key

export default getTextFromError
