import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { SyllabusCandidate } from '../../../types'
import { candidateColumnSizes, ICandidateUpdate } from '../constants'
import { GradeSelector } from '../utilities/grade-selector'

interface ICandidateGradingFull {
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  isErroneous: boolean
  viewOnly: boolean
}

export const CandidateGrading: React.FC<ICandidateGradingFull> = ({
  candidate: {
    id,
    centreSyllabusId,
    uniqueCandidateNumber,
    name,
    dob,
    grade,
    rank,
    allowedGrades,
    lastUpdated,
  },
  grading,
  candidatesInGrade,
  patchingProgress,
  isErroneous,
  viewOnly,
  onChange,
}): JSX.Element => {
  const sizes = candidateColumnSizes[3]

  const [pendingGrade, setPendingGrade] = useState<string|undefined>()

  useEffect(() => {
    if (!patchingProgress) {
      setPendingGrade(undefined)
    }
  }, [patchingProgress, setPendingGrade])

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-35'
          : 'candidate-row px-25 py-35'
      }
    >
      <Col className="text-left name" md={sizes[0]}>
        <Label className="candidate-name mb-1">{name}</Label>
        <Label className="candidate-uln mb-0">{uniqueCandidateNumber}</Label>
      </Col>
      <Col className="text-center" md={sizes[1]}>
        <GradeSelector
          value={grade}
          options={allowedGrades}
          pendingValue={patchingProgress === 'pending' ? pendingGrade : undefined}
          disabled={viewOnly}
          onChange={(val: string) => {
            setPendingGrade(val)
            onChange({ grade: val, rank })
          }}
        />
      </Col>
      <Col className="text-center text-body font-weight-bold" md={sizes[2]}>
        <Label>{rank || '–'}</Label>
      </Col>
    </Row>
  )
}
