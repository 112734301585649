import React, { useMemo, useState, useEffect } from 'react'
import { Button, ModalProps, Row, Spinner, Col } from 'reactstrap'
import { CentreSyllabus, PathParam } from '../../types'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import { ProjectModal } from '../modal/modal'
import ReactGA from 'react-ga'
import {
  faCheck,
  faExclamationTriangle,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { AxiosError, AxiosResponse } from 'axios'

import useReactRouter from 'use-react-router'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { Title } from '../title/title'
import getTextFromToken from '../../tokenised-text'
import { isCambridgeInternational, isOCR } from '../../util'
import { InlineErrorMessageNoBorder } from '../simple-message/inline-error-message'


export const ApproveModal: React.FC<ModalProps & CentreSyllabus> = ({
  setSyllabusCallback,
  syllabusName,
  qualification,
  totalCandidates,
  id,
  qualLevel,
  syllabusCode,
  isOpen,
  isOpenCallback,
}) => {
  const { match } = useReactRouter<PathParam>()

  const [showError, setShowError] = useState(false)

useEffect(()=>{
if (isOpen){
  setShowError(false)
}
}, [isOpen])

  const toggle = () => {
  
    if (sendForApprovalTask.result)
      setSyllabusCallback(sendForApprovalTask.result?.data)
    isOpenCallback(!isOpen)
  }

  const sendForApprovalMemo = useMemo(() => {
    return {
      method: 'patch',
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${match.params.id}/syllabuses/${match.params.syllabusId}`,
      data: {
        valuationStatus: 'submitted',
      },
    }
  }, [match.params.id, match.params.syllabusId])

  const sendForApprovalTask = useAsyncTaskAxios<AxiosResponse>(
    axios,
    sendForApprovalMemo
  )
 useEffect(()=>{
   if (sendForApprovalTask.error){
     setShowError(true)
   }else{
    setShowError(false)
   }
 }, [sendForApprovalTask.error])
  const headNotFound = useMemo(() => {
    if (sendForApprovalTask.error) {
      return (
        (sendForApprovalTask.error as AxiosError).response?.data ===
        'HoC not found'
      )
    }
    return false
  }, [sendForApprovalTask])

  const alreadySubmitted = useMemo(() => {
    if (sendForApprovalTask.error) {
      return (
        (sendForApprovalTask.error as AxiosError).response?.data ===
        'Invalid valuation status: submitted'
      )
    }
    return false
  }, [sendForApprovalTask])

  return (
    <ProjectModal centered isOpen={isOpen}>
      <RsModalHeader toggle={toggle} className="bg-white px-5 pt-5 pb-25">
        <Title
          title={syllabusName}
          subTitle={
            qualLevel
              ? `${qualification} - ${qualLevel} ${syllabusCode}`
              : `${qualification} ${syllabusCode}`
          }
          ancillery={`${totalCandidates} candidates`}
          titleColor="success"
        />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        {sendForApprovalTask.result && (
          <div>
            <Row className="align-items-center">
              <Col>
                <span className="font-weight-bold font-larger">
                  Your data has been sent for approval.
                </span>
                <FontAwesomeIcon icon={faCheck} className="ml-2" fixedWidth />
              </Col>
            </Row>
            {isCambridgeInternational() && (
              <p>
                Please wait while your Head of Centre reviews and approves your
                predicted grades and rank orders for this subject. You can still view your data
                from the Dashboard.{' '}
                <span style={{ textDecoration: 'underline' }}>
                  You now cannot make any changes.
                </span>
              </p>
            )}
            {isOCR() && (
              <p>
                Please wait while your Head of Centre reviews and approves your
                centre assessment grades and rank orders for this subject. You
                can still view your data from the Dashboard.{' '}
                <u>You now cannot make any changes.</u>
              </p>
            )}
            <Button color="e4" onClick={toggle}>
              Continue
            </Button>
          </div>
        )}
        {alreadySubmitted && (
          <div>
            <p className="font-weight-bold">
              Students data already submitted. Please wait for Head of Centre
              for further action.
            </p>
            <Button color="e4" onClick={toggle}>
              Close
            </Button>
          </div>
        )}
        {showError && headNotFound && (
          <div>
            <Row className="align-items-center mb-2">
              <Col>
                <span className=" font-weight-bold font-larger text-danger">
                  The system could not find a valid Head of centre
                </span>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="ml-2 text-danger"
                  fixedWidth
                />
              </Col>
            </Row>
            <p>
              {getTextFromToken('approvalNoHoc')}
            </p>
            <Button color="e4" onClick={toggle}>
              Close
            </Button>
          </div>
        )}
        {!alreadySubmitted && !(showError && headNotFound ) && !sendForApprovalTask.result  && (
          <div>
            <p className="font-weight-bold font-larger">Send for approval</p>
            {isCambridgeInternational() && (
              <p className="font-normal">
                You are about to send your predicted grades and rank orders for this subject to your Head of Centre to approve.{' '}
                <u>
                  You will not be able to make any changes once you have sent them for approval.
                </u>
                {' Are you sure you wish to send them?'}
              </p>
            )}
            {isOCR() && (
              <p>
                You are about to send your centre assessment grades and rank orders for this subject to your Head of Centre to approve.{' '}
                <u>
                  You will not be able to make any changes once you have sent
                  them for approval.
                </u>{' Are you sure you wish to send them?'}
                
              </p>
            )}
           {showError && sendForApprovalTask.error && (
            <InlineErrorMessageNoBorder title="Send for approval failed, please try again and if the problem persists contact your system administrator" />
        
            )}
            <Row className="mt-5 ">
              <Col xs="auto">
                <Button color="e4" onClick={toggle}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() =>{
                    ReactGA.event({
                      category: 'Grade Submission',
                      action: 'Submit',
                      label: id
                    })
                                       sendForApprovalTask.start()
                  }}
                  color="success"
                >
                  {sendForApprovalTask.started &&
                    sendForApprovalTask.pending && (
                      <Spinner size="sm" className="mr-2" />
                    )}
                  {!(
                    sendForApprovalTask.started && sendForApprovalTask.pending
                  ) && (
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="mr-2"
                      fixedWidth
                    />
                  )}
                  Confirm and Send
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </RsModalBody>
    </ProjectModal>
  )
}
