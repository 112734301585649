import {
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  IdToken,
  PopupLoginOptions,
  RedirectLoginResult,
  getIdTokenClaimsOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'

export enum ValuationStatus {
  NOT_STARTED = 'not_started',
  INCOMPLETE = 'incomplete',
  COMPLETE = 'complete',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  LOCKED = 'locked',
  OPENED = 'opened'
}

export interface SyllabusCandidate {
  id: string
  centreSyllabusId: string
  uniqueCandidateNumber: string
  name: string
  dob: string
  grade?: string
  rank?: number
  allowedGrades: string[]
  lastUpdated?: string
}

export interface CentreSyllabus {
  closed: boolean
  id: string
  centreId: string
  syllabusName: string
  syllabusCode: string
  qualification: string
  qualLevel: string
  lastUpdated?: number
  totalCandidates: number
  gradedCandidates: number
  valuationStatus: ValuationStatus
  percentageCompleted?: number
  tiesAllowed?: boolean
}

export interface PathParam {
  id: string
  syllabusId?: string
}

export interface ManagedUser {
  userId?: string
  name: string
  surname: string
  email: string
  role?: string
  confirmed?: boolean
}

export interface User {
  email: string
  centres: string[]
  user_id: string
  sub: string
  picture: string
  name: string
  nickname: string
  roles: string[]
  email_verified: boolean
  isPlaceholder?: boolean
}
export interface AuthCallback {
  onRedirectCallback?: (appState: any) => void
}
export interface AuthContextProps {
  isAuthenticated: boolean
  user?: User
  currentCentre?: string
  loading?: boolean
  loginWithPopup(o?: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
  setCurrentCentre(cenreId: string): void
}

export interface AppState {
  targetUrl: string
}
export interface AuthConfig {
  domain: string
  client_id: string
  audience?: string
  scope?: string
  role_url: string
  centre_url: string
}
export interface Config {
  available?: number
  auth0Config?: AuthConfig
  siteEnabled?: boolean
  deadline?: number
}
export interface ConfigState {
  loading: boolean
  config: Config
}
export interface PreSignedUploadUrl {
  url: string
  uuid: string
}
export interface UploadStatus {
  validationErrors: ValidationError[]
  status: string
  id: string
  updatedItems: number
  aggregatedErrors: { [key: string]: number }
}
export interface ValidationError {
  errorCode: string
  errorMessage: string
  fieldName: string
  fieldValue: string
  uniqueId: string
}
export interface MissingData {
  missingPredicted: number
  missingRank: number
  tiedRank: number
}
