import React from 'react'
import { Container } from 'reactstrap'
import { useAuth0 } from '../auth'
import { CentreSyllabusPage } from './centre-syllabus-page/centre-syllabus-page'
import { isCAAdmin } from '../util'
import { CambridgeAdminControls } from './ca-admin-controls'

export const HomePage: React.FC = (): JSX.Element => {
  const { user, currentCentre } = useAuth0()

  if (user && isCAAdmin(user)) {
    return <CambridgeAdminControls />
  }

  if (user && currentCentre) {
    return <CentreSyllabusPage centreId={currentCentre} />
  }
  return <Container></Container>
}
