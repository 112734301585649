import React from 'react'
import {ManagementPage} from './management-page'
import useReactRouter from 'use-react-router'
import {ROLES} from '../../util'
import {PathParam} from '../../types'
import {Redirect} from 'react-router-dom'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'ci') {
  INFO_DESCRIPTION = (
    <>
      The Head of Centre is responsible for reviewing and approving predicted
      grades and rank orders before submitting them to Cambridge International.
      They will also need to complete a <b>Head of Centre declaration.</b>
      Once you have added your Head of Centre, they will receive a confirmation
      email with a verification link. They will need to click the link to verify
      their account before they can review and approve{' '}
      <b>predicted grades and rank orders.</b>
    </>
  )
} else if (process.env.REACT_APP_BU === 'ocr') {
  INFO_DESCRIPTION = (
    <>
      The Head of Centre is responsible for reviewing and approving centre assessment grades and
      rank orders before submitting them to OCR. They will also need to complete a Head of centre
      declaration. If your current Head of Centre is not available or needs to delegate this role,
      remove your current Head of Centre below and then add the name and email address for their
      replacement. They will receive a confirmation email with a verification link. They will need
      to click the link to verify their account before they can review and approve data.
    </>
  )
}

export const ManageSignOffPageWithRoute: React.FC = (): JSX.Element => {
  const {match} = useReactRouter<PathParam>()
  if (match.params.id !== match.params.id.toUpperCase()) {
    return (
      <Redirect
        to={`/centres/${match.params.id.toUpperCase()}/manage/signOff`}
      />
    )
  }
  return <ManageSignOffPage centreId={match.params.id}/>
}
export const ManageSignOffPage: React.FC<{ centreId: string }> = ({
  centreId
}): JSX.Element => (
  <ManagementPage
    title="Set up Head of Centre"
    centreId={centreId}
    description={INFO_DESCRIPTION}
    buttonText="Add Head of Centre"
    emptyListMessage="No head of centre has been assigned"
    role={ROLES.HOC}
    currentUsersLabel="Current Head of Centre"
  />
)
