import React, { useState, useEffect, useContext, FC } from 'react'
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js'
import axios from 'axios'
// eslint-disable-next-line import/no-unresolved

import { AuthContextProps, User, AuthConfig, AuthCallback } from './types'
import { isCAAdmin } from './util'

import ReactGA from 'react-ga'

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<AuthContextProps>({
  isAuthenticated: false,
  user: undefined,

  currentCentre: undefined,
  handleRedirectCallback: () => {
    throw new Error('Not implemented')
  },
  loginWithRedirect: () => {
    throw new Error('Not implemented')
  },
  loginWithPopup: () => {
    throw new Error('Not implemented')
  },
  getIdTokenClaims: () => {
    throw new Error('Not implemented')
  },
  getTokenSilently: () => {
    throw new Error('Not implemented')
  },
  getTokenWithPopup: () => {
    throw new Error('Not implemented')
  },
  logout: (params?: { returnTo: string }) => {
    throw new Error('Not implemented')
  },
  setCurrentCentre: () => {
    throw new Error('Not implemented')
  },
})

const mapUser = (aoUser: any, roleUrl: string, centreUrl: string): User => {
  const roles: string[] = roleUrl in aoUser ? aoUser[roleUrl] : []
  const centres = aoUser[centreUrl]
  const mappedUser: User = aoUser
  mappedUser.roles = roles
  mappedUser.user_id = mappedUser.sub
  mappedUser.centres = centres

  // If no roles, check if a single role exists
  if (mappedUser.roles.length === 0) {
    const singleRoleUrl = roleUrl.replace(/roles$/, 'role')
    if (singleRoleUrl in aoUser) {
      const role = aoUser[singleRoleUrl]
      mappedUser.roles = typeof role === 'string' ? [role] : role
    }
  }

  return mappedUser
}

export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider: FC<AuthConfig & AuthCallback> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...rest
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [currentCentre, setCurrentCentre] = useState<string | undefined>(
    undefined
  )
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>(undefined)

  const [loading, setLoading] = useState(true)
  const [, setPopupOpen] = useState(false)
  useEffect((): void => {
    const initAuth0 = async (): Promise<void> => {
      try {
        const auth0FromHook = await createAuth0Client({
          ...rest,
        })
        setAuth0(auth0FromHook)

        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await auth0FromHook.handleRedirectCallback()
          onRedirectCallback(appState)
        }

        const isAuthenticatedNow = await auth0FromHook.isAuthenticated()

        setIsAuthenticated(isAuthenticatedNow)

        if (isAuthenticatedNow) {
          const aoUser = await auth0FromHook.getUser()
          const userToSet = mapUser(aoUser, rest.role_url, rest.centre_url)

          setUser(userToSet)

          if (userToSet) {
            ReactGA.set({dimension1: userToSet.user_id });
            ReactGA.set({ userId: userToSet.user_id })
            const singleCentre =
              !isCAAdmin(userToSet) && userToSet.centres.length === 1
                ? userToSet.centres[0]
                : sessionStorage.getItem(userToSet.user_id)
            singleCentre && setCurrentCentre(singleCentre)
            ReactGA.set({dimension2: singleCentre });
            ReactGA.set({ centreId: singleCentre })
          }
        }

        setLoading(false)
      } catch (e) {
        const auth0Client = new Auth0Client({ ...rest })
        await auth0Client.logout({ returnTo: window.location.origin })
      }
    }
    initAuth0()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth0Client) {
      const authInterceptor = axios.interceptors.request.use(
        async (request) => {
          if (
            request.url &&
            request.url.startsWith(process.env.REACT_APP_APIDOMAIN!)
          ) {
            try {
              const newToken = await auth0Client.getTokenSilently({ ...rest })
              request.headers['Authorization'] = `Bearer ${newToken}`
            } catch (e) {
              if (e.error === 'access_denied') {
                auth0Client.logout({
                  returnTo: `${window.location.origin}?error=${e.error}`,
                })
              }
              if (e.error === 'login_required') {
                // would be nicer to show a little popup saying session timedout and login button but at least it works now
                auth0Client.loginWithRedirect({
                  redirect_uri: window.location.origin,
                  appState: { targetUrl: window.location.pathname },
                })
              }
            }
          }
          return request
        }
      )
      return function cleanup() {
        axios.interceptors.request.eject(authInterceptor)
      }
    }
  }, [auth0Client, rest])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client!.loginWithPopup(params)
    } catch (error) {
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client!.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)

    const result = await auth0Client!.handleRedirectCallback()

    const user = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
    return result
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        currentCentre,
        handleRedirectCallback,
        loginWithPopup: loginWithPopup,

        getIdTokenClaims: auth0Client
          ? (p) => auth0Client.getIdTokenClaims(p)
          : () => {
              throw new Error('Not implemented')
            },
        loginWithRedirect: auth0Client
          ? (p) => auth0Client.loginWithRedirect(p)
          : () => {
              throw new Error('Not implemented')
            },
        getTokenSilently: auth0Client
          ? (p) => auth0Client.getTokenSilently(p)
          : () => {
              throw new Error('Not implemented')
            },
        getTokenWithPopup: auth0Client
          ? (p) => auth0Client.getTokenWithPopup(p)
          : () => {
              throw new Error('Not implemented')
            },
        logout: auth0Client
          ? (p) => auth0Client.logout(p)
          : () => {
              throw new Error('Not implemented')
            },
        setCurrentCentre: (newCentre: string) => {
          if (user && user.centres.includes(newCentre)) {
            setCurrentCentre(newCentre)
            ReactGA.set({dimension2: newCentre });
            ReactGA.set({ centreId: newCentre })
            sessionStorage.setItem(user.user_id, newCentre)
          }
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
