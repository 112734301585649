import React, { useMemo, useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { formatDate } from './candidates-list/constants'
import { CentreSyllabus } from '../types'
import { ProjectModal } from './modal/modal'
import { RsModalHeader } from './modal/subcomponents/modal-header'
import { RsModalBody } from './modal/subcomponents/modal-body'
import { Checkbox } from './checkbox'
import ReactMarkdown from 'react-markdown'

import ciDeclarationContent from './upload/ci-declaration-content.md'
import ocrDeclarationContent from './upload/ocr-declaration-content.md'
import { isCambridgeInternational, isOCR } from '../util'

const CHECKLIST: { heading: string; statements: string[] }[] = [
  {
    heading: 'Predicted grades and rank orders',
    statements: [
      'I confirm that these predicted grades, and the rank orders of candidates, represent the professional judgements made by my staff.',
      'I confirm that the evidence that I and my staff have seen satisfies me that each of the candidates has been taught the relevant syllabus content and prepared for the exams.',
      'I am confident that they honestly and fairly represent the grades that these candidates would have been most likely to achieve if they had taken their exams as planned.',
      'I confirm staff involved in predicting grades and rank ordering candidates have taken into account guidance about avoiding bias, which could include unconscious biases related to the gender, ethnicity, religion, classroom behaviour or other characteristics of the candidate.',
      'I confirm that staff involved in predicting grades and rank ordering candidates have taken into account guidance about special educational needs or adverse circumstances.',
      'I confirm that I have submitted any and all declarations of interest in accordance with section 2.1.5 of the Cambridge Handbook.',
    ],
  },
  {
    heading: 'Providing evidence',
    statements: [
      'I understand that Cambridge International may ask to be provided with the evidence for some or all of my candidates.',
      'I confirm that the evidence will remain available to me from now until the release of certificates.',
      'I will provide this evidence to Cambridge International within the timescales specified in any request for evidence.',
      'I understand that failure to provide evidence upon request, and within the timescales specified in the request, may mean that results will not be issued for my candidates.',
    ],
  },
  {
    heading: 'Malpractice',
    statements: [
      'I confirm that the predicted grades, and the rank orders of students, have not been discussed with candidates, their parents/legal guardians, or any other individuals outside the centre.',
      'I understand that any action that breaks Cambridge International regulations and potentially threatens the integrity of the grades issued may be treated as suspected malpractice in accordance with section 5.6 of the Cambridge Handbook. Suspected malpractice may result in the delay of results, disqualification of candidates and / or sanctions against my centre and centre staff, including withdrawal of my centre status or right to offer Cambridge qualifications.',
    ],
  },
  {
    heading:
      'Understanding what Cambridge International will do to determine grades',
    statements: [
      'I understand that Cambridge International will conduct a statistical standardisation exercise using statistical evidence from the historical performance of my centre in the syllabus as well as global performance statistics and other information.',
      'I understand that the standardisation exercise will enable Cambridge International to see whether the distribution of my centre’s predicted grades for June 2020 is what they would expect our candidates to achieve.',
      'I understand that if the distribution of our predicted grades is different from what might be expected based on historical performance of my centre in the syllabus and global performance statistics and other information, then Cambridge International may adjust my candidates’ grades so that the distribution of grades awarded matches expectations. ',
      'I understand that once I have submitted the predicted grades and rank orders they will be considered as final by Cambridge and there will be no further opportunity for me to update or amend the provided information under any circumstances.',
    ],
  },
]

interface IHOCApproveModal {
  syllabus: CentreSyllabus
  isOpen: boolean
  close: () => void
  approve: () => void
}

export const HOCApproveModal: React.FC<IHOCApproveModal> = ({
  syllabus,
  isOpen,
  close,
  approve,
}) => {
  const [checklist, updateChecklist] = useState(
    // CHECKLIST.map((element, index) =>
    //   element.statements.map((desc, idx) => ({ idx: String(index) + String(idx), desc, checked: false }))
    // ).flat()
    CHECKLIST.map((element, index) => ({ idx: index, checked: false }))
  )

  const [confirmation, updateConfirmation] = useState(false)

  const allChecked = useMemo(() => checklist.every((it) => it.checked), [
    checklist,
  ])

  const check = useMemo(
    () => (idx: number, check: boolean) => {
      updateChecklist((old) =>
        old.map((it) =>
          it.idx !== idx
            ? it
            : {
                ...it,
                checked: check,
              }
        )
      )
    },
    [updateChecklist]
  )

  return (
    <ProjectModal className="hoc-approval-modal" centered isOpen={isOpen}>
      <RsModalHeader toggle={close} className="bg-white px-5 pt-5 pb-25">
        <h6
          className="mb-0 font-weight-bold text-success"
          style={{ fontSize: '22px' }}
        >{`${syllabus.syllabusName}`}</h6>
        <h5 className="text-secondary font-weight-semi-bold">
          <span>{`${syllabus.qualification} (${syllabus.syllabusCode})`}</span>
          <span className="ml-3 mr-3">|</span>
          <span>{`${syllabus.totalCandidates} candidates`}</span>
        </h5>
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <Row className="mb-4">
          <Col>
            <div className="markdown-content">
              {isCambridgeInternational() && (
                <ReactMarkdown source={ciDeclarationContent} />
              )}
              {isOCR() && <ReactMarkdown source={ocrDeclarationContent} />}
            </div>
          </Col>
        </Row>
        {isCambridgeInternational() && (
          <Row>
            <Col className="ci-declarations">
              {CHECKLIST.map(({ heading, statements }, index) => (
                <div>
                  <h6>{heading}</h6>
                  <ul>
                    {statements.map((statement) => (
                      <li>{statement}</li>
                    ))}
                  </ul>
                  <Checkbox
                    value={checklist[index].checked}
                    toggle={(newValue) =>
                      void check(
                        index,
                        newValue !== undefined
                          ? newValue
                          : !checklist[index].checked
                      )
                    }
                    variant="yes-no"
                  />
                </div>
              ))}
            </Col>
          </Row>
        )}
        <Row className="mb-4">
          <Col className="d-inline-flex declaration-confirmation">
            <Checkbox
              value={confirmation}
              toggle={() => updateConfirmation(!confirmation)}
            />
            <h6 className="font-weight-bold m-0">
              I confirm that the grades and rank orders we submit are final and
              correct. I agree to the Head of Centre Declaration on behalf of my
              centre.
            </h6>
          </Col>
        </Row>
        <Row className="mt-4 ml-0 justify-content-between">
          <Col>
            <h6 className="font-weight-bold">Date authorised</h6>
            <h6 className="font-weight-bold text-secondary">
              {formatDate(new Date(), true, true)}
            </h6>
          </Col>
          <Col className="d-inline-flex justify-content-end align-items-center">
            {isCambridgeInternational() && (
              <Button
                onClick={approve}
                color="success"
                disabled={!(allChecked && confirmation)}
              >
                Submit
              </Button>
            )}
            {isOCR() && (
              <Button
                onClick={approve}
                className="bg-success"
                disabled={!confirmation}
              >
                Submit
              </Button>
            )}
          </Col>
        </Row>
      </RsModalBody>
    </ProjectModal>
  )
}
