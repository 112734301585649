import React from 'react'
import useReactRouter from 'use-react-router'
import { ROLES } from '../../util'
import { ManagementPage } from './management-page'
import { PathParam } from '../../types'
import { Redirect } from 'react-router-dom'
import getTextFromToken from '../../tokenised-text'

const INFO_DESCRIPTION = (
  <>
    Please enter your centre admins’ details. Centre admins will receive a confirmation
    email with a verification link. Users must click the link to gain access to
    the system. Centre admins will not be able to enter or upload{' '}
    <b>{getTextFromToken('grades').toLowerCase()} or rank orders</b> until they
    have done this.
  </>
)

export const ManageAdminsPageWithRoute: React.FC = (): JSX.Element => {
  const { match } = useReactRouter<PathParam>()
  if (match.params.id !== match.params.id.toUpperCase()) {
    return (
      <Redirect to={`/centres/${match.params.id.toUpperCase()}/manage/admins`} />
    )
  }
  return <ManageAdminsPage centreId={match.params.id} />
}

export const ManageAdminsPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Manage Centre Admins"
    description={INFO_DESCRIPTION}
    buttonText="Add centre admin"
    emptyListMessage="There are currently no admins at this centre"
    role={ROLES.Admin}
    currentUsersLabel="Current Centre Admins"
  />
)
