import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga'

declare global {
  interface Window {
    dataLayer: any
    gtag?: (
      key: string,
      trackingId: string,
      config?: { page_path: string }
    ) => void
  }
}

export const useTracking = (
  trackingId: string | undefined = process.env.REACT_APP_GA_MEASUREMENT_ID
) => {
  const { listen } = useHistory()

  useEffect(() => {
    if (!trackingId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `REACT_APP_GA_MEASUREMENT_ID`.'
      )
      return
    }
    ReactGA.initialize(trackingId)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [trackingId])

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `REACT_APP_GA_MEASUREMENT_ID`.'
        )
        return
      }
      
      ReactGA.pageview(window.location.pathname + window.location.search)
    })

    return unlisten
  }, [trackingId, listen])
}
