import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { Container, Row, Col, Button } from 'reactstrap'
import { format } from 'date-fns'
import { useAuth0 } from '../auth'
import { Link } from 'react-router-dom'
import { faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { isAdmin } from '../util'
import { useConfig } from './use-remote-config'

export const NotAvailable: React.FC = (): JSX.Element => {
  const { user } = useAuth0()
  const { config } = useConfig()
  return (
    <Container className="not-available">
      <FontAwesomeIcon size="2x" className="fa-icon" icon={faClock} />
      <h5 className="mt-4 font-weight-bold text-center">Welcome</h5>
      <p>
        The Grade Submission System will not be available for entering grades
        and rank orders{' '}
        <u>
          until{' '}
          {config.available
            ? format(new Date(config.available), 'MMMM do yyyy')
            : ''}{' '}
        </u>
      </p>
      {user && !isAdmin(user) && (
        <p className="mt-4">
          Please come back to the system at this date, thank you.
        </p>
      )}
      {user && isAdmin(user) && (
        <>
          <p className="mt-4">
            You can configure users and Sign Off authority now.
          </p>
          <Row className="mt-5">
            <Col className="text-right">
              <Button
                tag={Link}
                style={{ minWidth: '187px' }}
                to="/manage/users"
                color="lightButNotTooLight font-weight-bold text-secondary"
              >
                <FontAwesomeIcon icon={faUsers} />
                <div>Manage Users</div>
              </Button>
            </Col>
            <Col>
              <Button
                tag={Link}
                style={{ minWidth: '187px' }}
                to="/manage/signOff"
                color="lightButNotTooLight font-weight-bold text-secondary"
              >
                <FontAwesomeIcon icon={faUserShield} />
                <div>Set up Sign Off Authority</div>
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
