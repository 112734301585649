import React, {useMemo} from 'react'
import {Button, Card, CardBody, CardFooter, Col, Label, Progress, Row} from 'reactstrap'

import {Link} from 'react-router-dom'
import {CentreSyllabus, ValuationStatus} from '../../types'
import {Title} from '../title/title'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faEye
} from '@fortawesome/pro-regular-svg-icons'
import {faPaperPlane, faPen} from '@fortawesome/pro-solid-svg-icons'
import {isAdmin, isHOC, isTeacher, syllabusInProgress} from '../../util'
import {useAuth0} from '../../auth'

export const SyllabusProgress: React.FC<
  CentreSyllabus & React.HTMLAttributes<HTMLDivElement>
> = ({
  className,
  id,
  syllabusName,
  syllabusCode,
  qualification,
  lastUpdated,
  qualLevel,
  totalCandidates,
  gradedCandidates,
  centreId,
  valuationStatus,
  percentageCompleted,
  closed
}): JSX.Element => {

  const inProgress = syllabusInProgress(valuationStatus)
  const is = useMemo(() => (status: string) => valuationStatus === status, [
    valuationStatus,
  ])
  const { user } = useAuth0()

  return (
    <>
      <Link
        className="no-text-hover"
        to={`/centres/${centreId}/syllabus/${id}`}
      >
        <Card
          className={`${className} project-row transition-none`}
          data-testid={`syllabus-progress-${id}`}
        >
          <CardBody
            className={`px-42 pt-42 pb-4 ${
              inProgress ? '' : 'bg-lightButNotTooLight'
            }`}
          >
            <Row className="align-items-center">
              <Col>
                <Title
                  title={syllabusName || syllabusCode}
                  subTitle={
                    qualLevel
                      ? `${qualification} - ${qualLevel} ${syllabusCode}`
                      : `${qualification} ${syllabusCode}`
                  }
                />
              </Col>
              <Col xs="auto" className="text-right px-3">
                <span
                  className={`d-md-inline-block text-secondary font-weight-semi-bold`}
                >
                  <span
                    data-testid={`candidate-progress-${id}`}
                    className="mb-0 font-larger"
                  >
                    {gradedCandidates}
                    {' / '}
                    {totalCandidates}
                  </span>
                  <span>{' candidates completed'}</span>
                </span>
              </Col>
              {is(ValuationStatus.SUBMITTED) && closed && (
                <Col xs="auto" className="text-right px-3 ">
                  <span className="text-uppercase border border-2 rounded
                    font-weight-bold px-3 py-2 text-primary border-black37">
                    <Label className="black-color mb-0">
                          <FontAwesomeIcon className="mr-2" size="sm" icon={faExclamationTriangle}/>
                          <span>INCOMPLETE</span>
                        </Label>
                  </span>
                </Col>)}

              {inProgress && (
                <>
                  <Col xs="auto" className="text-right px-3 ">
                    <span
                      data-testid={`progress-status-${id}`}
                      className={`text-uppercase border border-2 rounded
                    font-weight-bold px-3 py-2 text-primary
                    ${
                      is(ValuationStatus.NOT_STARTED) || is(ValuationStatus.LOCKED)
                        ? 'border-black37'
                        : 'border-primary37'
                    }
                    `}
                    >
                      {is(ValuationStatus.NOT_STARTED) && !closed && (
                        <span className="text-secondary">NOT STARTED</span>
                      )}
                      {is(ValuationStatus.INCOMPLETE) && !closed && (
                        <span>IN PROGRESS</span>
                      )}
                      {is(ValuationStatus.COMPLETE) && !closed && <span>COMPLETED</span>}
                      {inProgress && closed && (
                        <Label className="black-color mb-0">
                          <FontAwesomeIcon className="mr-2" size="sm" icon={faExclamationTriangle}/>
                          <span>INCOMPLETE</span>
                        </Label>)}
                      {is(ValuationStatus.LOCKED) && (<Label className="black-color mb-0">
                        <FontAwesomeIcon className="mr-2" size="sm" icon={faClock}/>
                        <span>DATA BEING UPDATED</span>
                      </Label>)}
                    </span>
                  </Col>
                  {!is(ValuationStatus.SUBMITTED) && (
                    <Col xs="auto" className="text-right px-3">
                      {(user && (isTeacher(user) || isHOC(user) || isAdmin(user)) && !closed && !is(ValuationStatus.LOCKED)) ? (
                        <Button color="success" className="text-uppercase">
                          <FontAwesomeIcon
                            icon={is(ValuationStatus.COMPLETE) ? faPaperPlane : faPen}
                            className="mr-2 font-normal"
                          />
                          <span className="font-normal">{
                            is(ValuationStatus.COMPLETE) ? 'Send' : 'Edit'
                          }</span>
                        </Button>
                      ) : (
                        <Button color="success" className="text-uppercase">
                          <FontAwesomeIcon
                            icon={faEye}
                            className="mr-2 font-normal"
                          />
                          <span className="font-normal">View</span>
                        </Button>
                      )}
                    </Col>
                  )}
                </>
              )}
              {((user && isHOC(user) && is(ValuationStatus.SUBMITTED)) ||
                is(ValuationStatus.APPROVED)) && (
                <Col xs="auto" className="text-right px-3">
                  <span className=" text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-success">
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={
                        is(ValuationStatus.SUBMITTED) ? faEye : faCheckCircle
                      }
                    />
                    <span>
                      {is(ValuationStatus.SUBMITTED)
                        ? closed ? 'VIEW' :'VIEW AND APPROVE'
                        : 'APPROVED AND SUBMITTED'}
                    </span>
                  </span>
                </Col>
              )}
              {!(user && isHOC(user)) && is(ValuationStatus.SUBMITTED) && (
                <Col xs="auto" className="text-right px-3">
                  <span className=" text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-primary">
                    <FontAwesomeIcon className="mr-2" icon={faClock} />
                    AWAITING APPROVAL
                  </span>
                </Col>
              )}
            </Row>
          </CardBody>
          {(is(ValuationStatus.INCOMPLETE) || is(ValuationStatus.COMPLETE)) && (
            <CardFooter className="p-0 border-0">
              <Progress
                className="progress-slim-1 rounded-top-0 rounded-bottom-sm-down"
                max={100 || 0}
                value={percentageCompleted || 0}
              />
            </CardFooter>
          )}
        </Card>
      </Link>
    </>
  )
}
