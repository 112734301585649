import { BUSINESS_UNITS, getBusinessUnit } from './util'

const DICTIONARY_CI: { [key: string]: string } = {
  title:
    'Cambridge Assessment International Education | Grade Submission System',
  favicon: 'favicon-ci.ico',
  grades: 'Predicted Grades',
  syllabus: 'syllabus',
  syllabuses: 'syllabuses',
  supportMail: 'info@cambridgeinternational.org',
  supportTel: 'Phone (+44 1223 553 554)',
  helpGuideUri:
    'https://www.cambridgeinternational.org/covid/grade-submission-system/',
  privacyPolicyUri:
    'https://www.cambridgeinternational.org/privacy-and-legal/data-protection-and-privacy/',
  tandcUri:
    'https://www.cambridgeinternational.org/privacy-and-legal/terms-and-conditions/',
  filterText: 'Filter by grade / edit rank order',
  uploadMessage: 'Your template must be in CSV format. Uploading a template will replace any information that’s already been entered for this syllabus.',
  uploadStarted: 'We are uploading your template and checking for viruses. This may take a few moments. Once the upload is complete all existing data for this syllabus will be replaced.',
  uploadFinished: 'The information for this syllabus has now been updated, replacing any previously entered information. Please check your data before sending for approval.',
  uploadFailed: 'You will need to check and amend the errors below before sending for approval.',
  approvalNoHoc: 'Please contact your Centre Administrator. Currently there is no Head of Centre set up. Once a Head of Centre is set up, you will be able to send your syllabus for approval.',
}

const DICTIONARY_OCR: { [key: string]: string } = {
  title: 'OCR | Grade Submission System',
  favicon: 'favicon-ocr.ico',
  grades: 'Centre Assessment Grades',
  syllabus: 'subject',
  syllabuses: 'subjects',
  supportMail: 'general.qualifications@ocr.org.uk',
  supportTel: 'Phone (01223 553 998)',
  helpGuideUri: 'https://www.ocr.org.uk/supporting-you-during-coronavirus/grade-submission-system/',
  privacyPolicyUri:
    'https://www.ocr.org.uk/about/our-policies/website-policies/privacy-policy/',
  tandcUri:
    'https://www.ocr.org.uk/about/our-policies/website-policies/terms-and-conditions/',
  filterText: 'Filter by grade / add or edit rank order',
  uploadMessage: 'Your template must be in CSV format. Uploading a template will overwrite any centre assessment grades and rank orders currently in the system.',
  uploadStarted: 'We are uploading your template and checking for viruses. This may take a few moments. Once the upload is complete all existing data will be replaced.',
  uploadFinished: 'The information for this qualification has now been updated, replacing any previously entered information. Please check your data and complete it ready for sign off.',
  uploadFailed: 'All valid data will be imported, but the errors below will not be imported in to the system.',
  approvalNoHoc: 'Please contact your Centre Administrator. Currently there is no Head of Centre set up. Once a Head of Centre is set up, you will be able to send your qualification for approval.',
}

const dictionaryMap = {
  [BUSINESS_UNITS.CambridgeInternational]: DICTIONARY_CI,
  [BUSINESS_UNITS.OCR]: DICTIONARY_OCR,
}

const getTextFromToken = (key: string): string =>
  (dictionaryMap[getBusinessUnit()] || {})[key] || ''

export default getTextFromToken
