import React, { FC } from 'react'
import { SubjectPage } from './subject-page'
import { Route, Switch } from 'react-router'
import { NavBar } from './navbar'
import { HomePage } from './home'
import PrivateRoute from './private-route/private-route'
import {
  CentreSyllabusPageWithRoute,
} from './centre-syllabus-page/centre-syllabus-page'
import { ManageUsersPage, ManageUsersPageWithRoute } from './pages/manage-users'
import {
  ManageSignOffPage,
  ManageSignOffPageWithRoute,
} from './pages/manage-sign-off'

import { ROLES } from '../util'
import { useAuth0 } from '../auth'

import { useConfig } from './use-remote-config'
import HoldingPage from './holding-page/holding-page'
import { HelpAndContact } from './help-and-contact'
import { useTracking } from './use-tracking'
import { NotFound } from './not-found'
import { ManageAdminsPageWithRoute } from './pages/manage-admins'

export const RoutesWithHeader: FC = (): JSX.Element => {
  useTracking(process.env.REACT_APP_GA_TRACKING_CODE)

  const { user, currentCentre } = useAuth0()
  const config = useConfig()

  return (
    <div style={{ marginBottom: '5rem' }}>
      <NavBar />
      {config.config.siteEnabled && (
        <Switch>
          <Route exact path="/">
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          </Route>
          <Route exact path="/manage/users">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && <ManageUsersPage centreId={currentCentre} />}
            </PrivateRoute>
          </Route>
          <Route exact path="/manage/signoff">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && <ManageSignOffPage centreId={currentCentre} />}
            </PrivateRoute>
          </Route>
          <Route exact path="/centres/:id">
            <PrivateRoute>
              <CentreSyllabusPageWithRoute />
            </PrivateRoute>
          </Route>
          <Route exact path="/centres/:id/manage/users">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && <ManageUsersPageWithRoute />}
            </PrivateRoute>
          </Route>
          <Route exact path="/centres/:id/manage/admins">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && <ManageAdminsPageWithRoute />}
            </PrivateRoute>
          </Route>
          <Route exact path="/centres/:id/manage/signoff">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && <ManageSignOffPageWithRoute />}
            </PrivateRoute>
          </Route>
          <Route exact path="/centres/:id/syllabus/:syllabusId">
            <PrivateRoute>
              <SubjectPage />
            </PrivateRoute>
          </Route>
          <Route exact path="/help-and-contact">
            <PrivateRoute>
              <HelpAndContact />
            </PrivateRoute>
          </Route>
          <Route component={NotFound} />
        </Switch>
      )}
      {!config.config.siteEnabled && <HoldingPage />}
    </div>
  )
}
