import { Button, Col, Container, Label, Nav, Row, Spinner } from 'reactstrap'
import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import {
  faArrowToBottom,
  faArrowToTop,
  faClock,
  faEye,
  faPaperPlane,
  faCheck,
  faCheckCircle,
  faArrowLeft, faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import querystring from 'querystring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useReactRouter from 'use-react-router'
import { Title } from './title/title'

import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { Loading } from './loading'
import {
  CentreSyllabus,
  PathParam,
  SyllabusCandidate,
  ValuationStatus,
} from '../types'
import { CandidatesListContainer } from './candidates-list/candidates-list-container'
import { ManageUploadModal } from './upload/manage-upload'
import { isForbidden , isBadRequest} from './axios-error-helpers'
import { SimpleMessage } from './simple-message/simple-message'
import { SimpleErrorMessage } from './simple-message/simple-error-message'
import { DownloadModal } from './upload/download-modal'
import { ApproveModal } from './upload/approve-modal'
import { formatDate } from './candidates-list/constants'
import { useAuth0 } from '../auth'
import { isHOC, syllabusInProgress, isCAAdmin } from '../util'
import { HOCApproveModal } from './hoc-approve-modal'
import getTextFromToken from '../tokenised-text'
import { NotAvailable } from './not-available'
import { useConfig } from './use-remote-config'
import { CAdminBanner } from './ca-admin-banner'
import { Redirect } from 'react-router-dom'
import { InlineErrorMessageNoBorder } from './simple-message/inline-error-message'
import { format } from 'date-fns'

export const SubjectPage: React.FC = (): JSX.Element => {
  const { config } = useConfig()
  const { match, location } = useReactRouter<PathParam>()
  const { user } = useAuth0()
  const [uploadModalShow, setUploadModalShow] = useState(false)
  const [downloadModalShow, setDownloadModalShow] = useState(false)
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [approvalDisabled, setApprovalDisabled] = useState(true)
  const [lastUploadComplete, setLastUploadComplete] = useState(0)
  const [showHOCApproval, setShowHOCApproval] = useState(false)
  const [uploadedWithErr, setUploadedWithErr] = useState(false)
  const [syllabus, setSyllabus] = useState<CentreSyllabus | undefined>()

  const centreId = match.params.id
  const syllabusId = match.params.syllabusId

  const gradeFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.grade) {
      return 'ALL'
    }

    if (Array.isArray(qs.grade)) {
      return qs.grade[0]
    }

    return qs.grade
  }, [location])


  const [valuationStatus, setValuationStatus] = useState<string>('')
  useEffect(() => {
    setValuationStatus(syllabus?.valuationStatus || '')
  }, [syllabus])

  const viewOnly = useMemo(
    () =>
      !syllabusInProgress(syllabus?.valuationStatus) ||
      (user !== undefined && isCAAdmin(user, true)) ||
      syllabus?.closed === true ||
      syllabus?.valuationStatus === 'locked',
    [user, syllabus]
  )

  const lastUpdatedString = useMemo(
    () =>
      formatDate(
        new Date(syllabus?.lastUpdated || Date.now()),
        true,
        true
      ).replace(/\//g, ' / '),
    [syllabus]
  )

  const getCandidatesMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    }),
    [centreId, syllabusId, lastUploadComplete]
  )
  const getCandidatesTask = useAsyncTaskAxios<
    AxiosResponse<SyllabusCandidate[]>
  >(axios, getCandidatesMemo)

  useAsyncRun(getCandidatesTask)

  useEffect(() => {
    if (uploadedWithErr) {
      setShowErrors(true)
    }
  }, [getCandidatesTask.result, uploadedWithErr])

  const isApprovalOpenCallback = (state: boolean) => {
    setShowApprovalModal(state)
  }

  const patchSyllabusMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}`,
      method: 'patch'
    }),
    [centreId, syllabusId, lastUploadComplete]
  )
  const patchSyllabusTask = useAsyncTaskAxios<
    AxiosResponse<CentreSyllabus>
  >(axios, patchSyllabusMemo)

  const badPatch = useMemo(() => {
    if (!patchSyllabusTask.error) {
      return false
    }
    return isBadRequest((patchSyllabusTask.error as AxiosError).response)
  }, [patchSyllabusTask.error])

  const getCentreSyllabusMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}`,
    }
  }, [centreId, syllabusId, badPatch])

  const getCentreSyllabusTask = useAsyncTaskAxios<
    AxiosResponse<CentreSyllabus>
  >(axios, getCentreSyllabusMemo)
  useAsyncRun(getCentreSyllabusTask)

  const forbidden = useMemo(() => {
    if (!getCentreSyllabusTask.error) {
      return false
    }
    return isForbidden((getCentreSyllabusTask.error as AxiosError).response)
  }, [getCentreSyllabusTask.error])

  useEffect(() => setSyllabus(getCentreSyllabusTask.result?.data),
    [getCentreSyllabusTask.result])

  const viewOnlyState = useMemo<string>(() => {
    if (user && isHOC(user) && valuationStatus !== ValuationStatus.APPROVED ) {
      return 'hoc'
    }
    return valuationStatus
  }, [valuationStatus, user, patchSyllabusTask.error ])

  useEffect(() => {
    if (patchSyllabusTask.result){
      setSyllabus(patchSyllabusTask.result.data)
    }
  }, [patchSyllabusTask.result])
  
  const approvalDisabledCallback = (state: boolean) => {
    setApprovalDisabled(state)
  }

  const setSyllabusCallback = (syllabus: CentreSyllabus | undefined) => {
    setSyllabus(syllabus)
  }

  if (centreId !== centreId.toUpperCase()) {
    return (
      <Redirect
        to={`/centres/${centreId.toUpperCase()}/syllabus/${syllabusId}`}
      />
    )
  }
  if (user && !isCAAdmin(user) && Date.now() < Number(config.available)) {
    return <NotAvailable />
  }

  if (syllabusId && getCentreSyllabusTask.result) {
    return (
      <>
        <CAdminBanner centreId={centreId} showBanner linkBack />
        {showErrors && (
          <Nav className="error-nav bg-danger text-white font-weight-bold py-4">
            <Container>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                fixedWidth
                className="mr-3"
              />
              This {getTextFromToken('syllabus')} contains errors
            </Container>
          </Nav>
        )}


{patchSyllabusTask.error && (
          <Nav
            className={`text-white font-weight-bold py-3 bg-danger`}
          >
            <Container >
              <InlineErrorMessageNoBorder isBgDanger title={`Failed to update ${getTextFromToken('syllabus')}, please try again and if the problem persists please contact us`} />
            </Container>
          </Nav>
)}
        {syllabusInProgress(syllabus?.valuationStatus) && syllabus?.closed && (
          <Nav
            className={`text-white font-weight-bold py-3 bg-danger`}
          >
            <Container >
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              (INCOMPLETE) The deadline for submitting grades and rank orders <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {(syllabus?.valuationStatus === ValuationStatus.SUBMITTED && syllabus.closed) && (
          <Nav
            className={`text-white font-weight-bold py-3 bg-danger`}
          >
            <Container >
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              (INCOMPLETE) The deadline for submitting grades and rank orders <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {syllabus?.valuationStatus === 'locked' && (
          <Nav
            className="top-border text-white font-weight-bold py-3 bg-primary"
          >
            <Container >
              <FontAwesomeIcon className="mr-2" icon={faClock} />
              (UPDATING) We have closed this subject temporarily whilst we make amendments to the entries.
              <ul className="mt-3 ml-4">
                <li>Please come back later to amend centre assessment grades and rank orders, then submit</li>
                <li><u>If already submitted</u>, please submit again with the new entries.</li>
              </ul>
            </Container>
          </Nav>
        )}
        {viewOnly && !syllabusInProgress(valuationStatus) && !syllabus?.closed && (
          <Nav
            className={`text-white font-weight-bold py-3 ${
              ['approved', 'hoc'].includes(viewOnlyState)
                ? 'bg-success'
                : 'bg-primary'
            }`}
          >
            <Container >
              <Row className="align-items-center">

              {['approved', 'hoc', 'submitted'].includes(viewOnlyState) && (
                <Col>
                <span className="d-inline-flex align-items-center">
                  <FontAwesomeIcon
                    icon={
                      // @ts-ignore
                      {
                        approved: faCheckCircle,
                        hoc: faBell,
                        submitted: faClock,
                      }[viewOnlyState]
                    }
                    fixedWidth
                    className="mr-3"
                  />
                  {
                    // @ts-ignore
                    {
                      approved: `This ${getTextFromToken("syllabus")} has been approved and submitted`,
                      hoc: `This ${getTextFromToken("syllabus")} is waiting for your approval`,
                      submitted:
                      `This ${getTextFromToken("syllabus")} is waiting for approval from the Head of Centre`,
                    }[viewOnlyState]
                  }
                </span>
                </Col>
              )}
              {['hoc'].includes(viewOnlyState)  && !(patchSyllabusTask.started && patchSyllabusTask.pending) && (
                <>
                <Col xs="auto" >
                <Button color="link" className="px-0 text-white" onClick={() => {
                  ReactGA.event({
                    category: 'Grade Submission',
                    action: 'Reject',
                    label: syllabusId
                  })
                  patchSyllabusTask.start({ data: {
                    valuationStatus: ValuationStatus.COMPLETE
                  }})

                }}>

                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    <span className="underline">
                      Send back for a further review
                    </span>
                    </Button>
                    </Col>
                    <Col xs="auto" >
                    <Button
                      color="white"

                      className=" text-success "
                      onClick={() => {
                        setShowHOCApproval(true)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        fixedWidth
                        className="mr-2"
                      />
                      APPROVE
                    </Button>
                    </Col>
                    </>

              )}
              {viewOnlyState === 'hoc' && patchSyllabusTask.started && patchSyllabusTask.pending && (
                <Col xs="auto" >
                  <Spinner size="sm" className="mr-2" />
                  Updating
                </Col>
              )}
              </Row>

            </Container>
          </Nav>
        )}

        <div
          className={`px-3 mb-5 ${
            viewOnly ? 'bg-lightButNotTooLight py-42' : 'bg-light py-45'
          }`}
        >
          <Container>
            {syllabus && !viewOnly && (
              <>
                <Row>
                  <Col className="my-3">
                    <Title
                      title={syllabus?.syllabusName || ''}
                      subTitle={`${syllabus?.qualification}${
                        syllabus?.qualLevel ? ` - ${syllabus.qualLevel}` : ''
                      } ${syllabus?.syllabusCode}`}
                      ancillery={`${syllabus?.totalCandidates} candidates`}
                    />
                  </Col>
                  {getCandidatesTask.result && (
                  <Col
                    xs={24}
                    md="auto"
                    className="text-center text-md-right mt-0 mt-md-3 mb-4"
                  >
                    <Button
                      color="e4"
                      className="mr-3 text-center text-secondary"
                      onClick={() => setDownloadModalShow(true)}
                    >
                      <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />

                      <div>Download Template</div>
                    </Button>
                    <Button
                      color="e4"
                      className="mr-3 text-center text-secondary"
                      onClick={(e) => {
                        e.preventDefault()
                        setUploadModalShow(!uploadModalShow)
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowToTop} fixedWidth/>
                      <div>Upload Template</div>
                    </Button>
                    <Button
                      disabled={approvalDisabled}
                      color="success"
                      className="text-center text-white"
                      onClick={(e) => {
                        e.preventDefault()
                        setShowApprovalModal(!showApprovalModal)
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} fixedWidth/>
                      <div>Send for approval</div>
                    </Button>
                  </Col>
                  )}
                </Row>
                <Row className="mb-4">
                  <Col lg={11} className="text-dark">
                    {approvalDisabled ? (
                      <div>
                        Enter your candidates' grades and rank order. Your data
                        is saved automatically. Send for approval once all data
                        is correct. You can:
                      </div>
                    ) : (
                      <div>
                        All data has been entered / uploaded correctly, checked
                        and automatically saved.{' '}
                        <strong>You can send for approval now.</strong>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  {approvalDisabled && (
                    <Col className="text-dark">
                      <ul className="list-inline font-weight-bold mb-0">
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Download and
                          upload templates
                        </li>
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Enter or edit
                          grades and rank order on screen
                        </li>
                        <li className="list-inline-item mr-3">
                          <span className="mr-2">&bull;</span> Check for errors
                        </li>
                      </ul>
                    </Col>
                  )}
                   {!viewOnly && config.deadline &&

                    <Col xs={approvalDisabled ? 'auto': '24'} className="text-secondary font-weight-bold text-right">

                      <FontAwesomeIcon icon={faClock} className="mr-2" />

                      {`Deadline is ${format(new Date(config.deadline), 'MMMM do yyyy')}`}

                    </Col>

                    }
                </Row>
              </>
            )}

            {syllabus && viewOnly && (
              <Row>
                <Col>
                  <Col>
                    <h3
                      className={`h5 mb-1 font-weight-bold ${
                        viewOnlyState === 'approved'
                          ? 'text-success'
                          : 'text-primary'
                      }`}
                    >
                      {syllabus?.syllabusName}
                    </h3>
                    <div className="font-larger font-weight-bold text-secondary">
                      {`${syllabus?.qualification}${
                        syllabus?.qualLevel ? ` - ${syllabus.qualLevel}` : ''
                      } ${syllabus?.syllabusCode}`}
                      <>
                        <span className="text-black37 mx-2">|</span>{' '}
                        {`${syllabus?.totalCandidates} candidates`}
                      </>
                    </div>
                  </Col>
                </Col>
                <Col className="text-right">
                  <Label
                    className="d-flex justify-content-end font-weight-bold font-normal"
                    style={{ lineHeight: '16px' }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faEye} />
                    This is a view only mode
                  </Label>
                  <Label
                    className="d-flex justify-content-end opacity-59 font-weight-semi-bold"
                    style={{ lineHeight: '16px' }}
                  >
                    Last updated on {lastUpdatedString}
                  </Label>
                </Col>
              </Row>
            )}
          </Container>
        </div>
        {getCandidatesTask.pending && (
          <Container>
            <SimpleMessage
              className="mb-5"
              icon={<Loading className="d-block mx-auto" />}
              title="Retrieving candidates..."
            />
          </Container>
        )}
         {!forbidden && !getCentreSyllabusTask.error && getCandidatesTask.error && (
          <Container>
            <SimpleErrorMessage title={`Failed to load candidates, please refresh and if the problem persists contact your system administrator`} allowPageRefresh/>
          </Container>
        )}
        {syllabus && getCandidatesTask.result && (
          <>
            <DownloadModal
              {...getCentreSyllabusTask.result?.data}
              toggle={() => setDownloadModalShow(!downloadModalShow)}
              isOpen={downloadModalShow}
            />
            <ManageUploadModal
              onUploadComplete={(val: boolean) => {
                setUploadedWithErr(val)
                setUploadModalShow(false)
                setLastUploadComplete(new Date().getTime())
              }}
              toggle={() => {
                setUploadModalShow(!uploadModalShow)
              }}
              {...getCentreSyllabusTask.result?.data}
              isOpen={uploadModalShow}
            />
            <ApproveModal
              {...getCentreSyllabusTask.result.data}
              isOpen={showApprovalModal}
              setSyllabusCallback={setSyllabusCallback}
              isOpenCallback={isApprovalOpenCallback}
            />
            <CandidatesListContainer
              approvalDisabled={approvalDisabledCallback}
              gradeFilter={gradeFilter}
              allowTies={syllabus.tiesAllowed}
              candidates={getCandidatesTask.result?.data}
              showErrors={showErrors}
              toggleShowErrors={(newShow) => {
                setShowErrors(newShow)
              }}
              downloadCallback={() => setDownloadModalShow(true)}
              centreId={centreId}
              syllabusId={syllabusId}
              lastUpdated={Number(syllabus?.lastUpdated)}
              viewOnly={viewOnly}
            />
          </>
        )}
        {syllabus && (
          <HOCApproveModal
            syllabus={syllabus}
            isOpen={showHOCApproval}
            close={() => setShowHOCApproval(false)}
            approve={() => {
              ReactGA.event({
                category: 'Grade Submission',
                action: 'Approve',
                label: syllabusId
              })
              setShowHOCApproval(false)
              patchSyllabusTask.start({data:{valuationStatus:ValuationStatus.APPROVED}})
            }}
          />
        )}
      </>
    )
  }
  return (
    <Container className="mt-5">
      {getCentreSyllabusTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={<Loading className="d-block mx-auto" />}
          title={`Retrieving ${getTextFromToken('syllabus')}...`}
        />
      )}
      {forbidden && (
        <SimpleErrorMessage title="You do not have permission to submit for this centre" />
      )}
      {!forbidden && getCentreSyllabusTask.error && (
          <SimpleErrorMessage title={`Failed to load ${getTextFromToken('syllabus')}, please refresh and if the problem persists contact your system administrator`} allowPageRefresh/>
      )}
     
    </Container>
  )
}
