import { Button, ModalProps, Spinner } from 'reactstrap'
import React, { FC, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga'
import { CentreSyllabus, ValuationStatus } from '../../types'
import { ProjectModal } from '../modal/modal'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDownloadFile } from '../use-download-file'
import { isOCR, isCambridgeInternational } from '../../util'
import { faArrowToBottom, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Title } from '../title/title'
import { RsModalFooter } from '../modal/subcomponents/modal-footer'
import { InlineErrorMessageNoBorder } from '../simple-message/inline-error-message'

export const DownloadModal: FC<ModalProps & CentreSyllabus> = ({
  parentCallback,
  id,
  syllabusCode,
  toggle,
  syllabusName,
  valuationStatus,
  qualLevel,
  totalCandidates,
  centreId,
  isOpen,
  qualification,
}) => {
  const download = useDownloadFile({
    fileUrl: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/candidates/csv`,
    fileName: qualLevel
      ? `${centreId}_${syllabusCode}_${qualLevel}.csv`
      : `${centreId}_${syllabusCode}.csv`,
  })
  const subTitle = useMemo(()=>{

    return qualLevel
              ? `${qualification} - ${qualLevel} ${syllabusCode}`
              : `${qualification} ${syllabusCode}`
  }, [qualification, qualLevel, syllabusCode])
  useEffect(() => {
    if (download.result) {
      const timer = setTimeout(toggle as any, 500)
      return () => clearTimeout(timer)
    }
  }, [download.result])
  return (
    <ProjectModal
      centered
      isOpen={isOpen}
      toggle={!(download.pending && download.started) ? toggle : undefined}
    >
      <RsModalHeader
        toggle={
          !(download.pending && download.started) ? (toggle as any) : undefined
        }
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={syllabusName}
          subTitle={subTitle}
          ancillery={`${totalCandidates} candidates`}
        />
      </RsModalHeader>
      <RsModalBody className="px-5">
        <div className="font-weight-bold">Download Template</div>
        {[ValuationStatus.NOT_STARTED,ValuationStatus.INCOMPLETE, ValuationStatus.COMPLETE ].includes(valuationStatus) &&  (
          <>
            <p>
              This template is a CSV file that has been pre-populated with
              candidate names and numbers. It will also include any data you
              have already added in the system.
            </p>
            <p>
              Once you have downloaded and filled in the template, you can
              return to this page to upload it.
            </p>
          </>
        )}
        {valuationStatus === ValuationStatus.SUBMITTED &&  (
          <>
            <p>
              This template is a CSV file that contains all the data you have provided. It is awaiting approval by your Head of Centre and cannot currently be edited. <u>If you require assistance please contact us.</u>
            </p>
        
          </>
        )}
         {valuationStatus === ValuationStatus.APPROVED &&  (
          <>
            <p>
            This template is a CSV file that contains all the data you have provided. It has been approved and submitted by your Head of Centre and cannot be edited. <u>If you require assistance please contact us.</u>
            </p>
        
          </>
        )}
        {download.error && (
          <InlineErrorMessageNoBorder title="Download failed, please try again and if the problem persists contact your system administrator" />
        )}
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        <Button
          className="ml-0"
          disabled={download.pending && download.started}
          color={download.result ? 'success' : 'primary'}
          onClick={() => {
            ReactGA.event({
              category: 'Grade Submission',
              action: 'Download Template',
              label: id
            })
            download.start()}}
        >
          {download.pending && download.started && !download.result && (
            <Spinner className="mr-2" size="sm" />
          )}
          {!(download.pending && download.started) && !download.result && (
            <FontAwesomeIcon className="mr-2" icon={faArrowToBottom} />
          )}
          {download.result && (
            <FontAwesomeIcon className="mr-2" icon={faCheck} />
          )}
          Download
        </Button>
      </RsModalFooter>
    </ProjectModal>
  )
}
