import {User, ValuationStatus} from './types'

export const ROLES = {
  CA_Admin: 'CA Admin',
  Admin: 'Centre Admin',
  HOC: 'Head of Centre',
  Teacher: 'Teacher',

  QA_Super: 'QA Super',
}

export const isSuperUser = (user: User) => user.roles.includes(ROLES.QA_Super)

export const isCAAdmin = (user: User, hardCheck?: boolean) =>
  user.roles.includes(ROLES.CA_Admin) || (!hardCheck && isSuperUser(user))
export const isAdmin = (user: User) =>
  user.roles.includes(ROLES.Admin) || isSuperUser(user)
export const isHOC = (user: User) =>
  user.roles.includes(ROLES.HOC) || isSuperUser(user)
export const isTeacher = (user: User) =>
  user.roles.includes(ROLES.Teacher) || isSuperUser(user)

export const syllabusInProgress = (status: any) =>
  [
    ValuationStatus.NOT_STARTED,
    ValuationStatus.INCOMPLETE,
    ValuationStatus.COMPLETE,
    ValuationStatus.LOCKED
  ].includes(status)

export const BUSINESS_UNITS = {
  CambridgeInternational: 'ci',
  OCR: 'ocr',
}

export const getBusinessUnit = () =>
  process.env.REACT_APP_BU &&
  Object.values(BUSINESS_UNITS).includes(process.env.REACT_APP_BU)
    ? process.env.REACT_APP_BU
    : BUSINESS_UNITS.CambridgeInternational

export const isCambridgeInternational = () =>
  getBusinessUnit() === BUSINESS_UNITS.CambridgeInternational
export const isOCR = () => getBusinessUnit() === BUSINESS_UNITS.OCR
