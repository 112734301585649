import React from 'react'
import {Spinner} from 'reactstrap'
import getTextFromToken from '../../../tokenised-text'

export const UploadStarted: React.FC<{ fileName: string }> = ({fileName}) => {
  return (
    <div className="modal-margin">
      <p className=" list-inline-item font-weight-bold">
        Your template is uploading
      </p>
      <p className="list-inline-item">{`(${fileName})`}</p>
      <Spinner className="list-inline-item" size="sm" color="primary"/>
      <p>
        {getTextFromToken('uploadStarted')}
      </p>
    </div>
  )
}
