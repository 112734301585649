import React, { useMemo, useState } from 'react'

const clamp = (num: number, min: number, max: number) =>
  Math.max(min, Math.min(num, max))

const orEmpty = (a?: any): string => (a ? `${a}` : '')

interface IRankSelector {
  value?: number
  max: number
  onChange: (value: any, cb: (good: boolean) => void) => void
  tabIndex: number
  disabled?: boolean
}
export const RankSelector: React.FC<IRankSelector> = ({
  value,
  max,
  onChange,
  tabIndex,
  disabled
}): JSX.Element => {
  const [insideValue, setValue] = useState(value ? `${value}` : '')

  const updateValue = useMemo(
    () => (val: string) => {
      const clampedValue =
        val.length > 0
          ? Number.isInteger(Number(val)) && clamp(Number(val), 1, max)
          : ''

      if ((clampedValue || clampedValue === '') && clampedValue !== value) {
        setValue(orEmpty(clampedValue))
        onChange(clampedValue !== '' ? clampedValue : undefined, (good) => {
          if (!good) setValue(orEmpty(value))
        })
      } else if (val !== orEmpty(value) && clampedValue) {
        setValue(orEmpty(clampedValue))
      }
    },
    [value, max, onChange]
  )

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e && e.preventDefault && e.preventDefault()
      e.target.blur()
    }
  }

  const onInput = (val: string) => {
    ;(val === '' || /^\d+$/.test(val)) && setValue(val)
  }

  return (
    <div className="rank-selector">
      <input
        className={`p-1 text-center ${value ? '' : 'empty'}`}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        onChange={(e) => onInput(e.target.value)}
        value={insideValue}
        onBlur={() => updateValue(insideValue)}
        type="text"
        disabled={disabled}
      />
    </div>
  )
}
