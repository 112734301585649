import React, { FC, useContext, useMemo } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import { Config, ConfigState } from '../types'

export const ConfigContext = React.createContext<ConfigState>({
  loading: true,
  config: {},
})

export const useConfig = (): ConfigState => useContext(ConfigContext)

export const ConfigProvider: FC = ({ children }): JSX.Element => {
  const getConfigMemo = useMemo(() => {
    return {
      url: process.env.REACT_APP_CONFIGPATH || `/config.json?${new Date().getTime()}`,
    }
  }, [])

  const getConfigTask = useAsyncTaskAxios<AxiosResponse<Config>>(
    axios,
    getConfigMemo
  )
  useAsyncRun(getConfigTask)
  return (
    <ConfigContext.Provider
      value={
        getConfigTask.result
          ? { loading: false, config: getConfigTask.result.data }
          : { loading: true, config: {} }
      }
    >
      {getConfigTask.result && children}
    </ConfigContext.Provider>
  )
}
