export const candidateColumnSizes: { [key: number]: number[] } = {
  3: [6, 14, 4],
  5: [6, 4, 4, 6, 3], //1 left over for extra space at the end
}

export interface ICandidateUpdate {
  grade: string
  rank?: number
}

const getTimezone = (d: Date) => {
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
  return (
    (dtf.formatToParts(d).find((part) => part.type === 'timeZoneName') || {})
      .value || ''
  )
}

export const formatDate = (date: Date, utc = false, full = false): string => {
  const dd = utc ? date.getUTCDate() : date.getDate()
  const mm = (utc ? date.getUTCMonth() : date.getMonth()) + 1
  const yyyy = utc ? date.getUTCFullYear() : date.getFullYear()
  const yy = ('' + yyyy).slice(2)

  const timezone = utc ? 'UTC' : getTimezone(date)
  const hh = utc ? date.getUTCHours() : date.getHours()
  const min = utc ? date.getUTCMinutes() : date.getMinutes()

  const part1 = `${dd < 10 ? '0' : ''}${dd}/${mm < 10 ? '0' : ''}${mm}/${
    full ? yyyy : yy
  }`
  const part2 = `${timezone ? ` ${timezone}` : ''} ${hh < 10 ? '0' : ''}${hh}:${
    min < 10 ? '0' : ''
  }${min}`

  return full ? part1 + part2 : part1
}

export const UNRANKED_GRADES = ['X']
