import { useEffect, useMemo, useState } from 'react'
import { useAsyncRun, useAsyncTaskAxios, AsyncTask } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

import { ManagedUser } from '../../types'

const buildRequest = (centreId: string, method?: string, body?: string) => ({
  url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/users`,
  headers: {
    Accept: 'application/json',
  },
  method,
  body,
})

export const useUserManagement = (
  centreId: string
): [
  ManagedUser[],
  AsyncTask<AxiosResponse<ManagedUser>, unknown[]>,
  AsyncTask<AxiosResponse<ManagedUser[]>, unknown[]>,
  (email: string) => void
] => {
  const [userList, setUserList] = useState<ManagedUser[]>([])

  const getUsersMemo = useMemo(() => buildRequest(centreId), [centreId])
  const getUsersTask = useAsyncTaskAxios<AxiosResponse<ManagedUser[]>>(
    axios,
    getUsersMemo
  )

  useAsyncRun(getUsersTask)

  const createUserMemo = useMemo(() => buildRequest(centreId, 'POST'), [
    centreId,
  ])
  const createUserTask = useAsyncTaskAxios<AxiosResponse<ManagedUser>>(
    axios,
    createUserMemo
  )

  useEffect(() => {
    if (createUserTask.result) {
      setUserList((oldList) => [
        ...oldList.filter(
          (user) => user.email !== createUserTask.result.data.email
        ),
        createUserTask.result.data,
      ])
    }
  }, [createUserTask.result])

  useEffect(() => {
    setUserList((getUsersTask.result || {}).data || [])
  }, [getUsersTask.result])

  const deleteUser = (email: string) => {
    setUserList((oldList) => [
      ...oldList.filter((user) => user.email !== email),
    ])
  }

  return [userList, createUserTask, getUsersTask, deleteUser]
}
