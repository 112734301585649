import React, { useState, useMemo } from 'react'
import ReactGA from 'react-ga'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Container, Spinner } from 'reactstrap'
import { SearchBar } from './search-bar/search-bar'
import { useDownloadFile } from './use-download-file'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import axios,  { AxiosResponse } from 'axios'
import { PreSignedUploadUrl } from '../types'

export const CambridgeAdminControls: React.FC = (): JSX.Element => {
  const { history } = useReactRouter()


  const getPresignedUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/progressUrl`
     
    }
  }, [])
  const getAuditPresignedUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/auditlog/url`

    }
  }, [])

  const getPresignedAuditUrlTask = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
      axios,
      getAuditPresignedUrl
  )


  const getPresignedUrlTask = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
    axios,
    getPresignedUrl
  )

  const downloadCentreProgress = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: `centres_download.csv`,
  })
  const downloadAuditLogs = useDownloadFile({
    fileUrl: `${getPresignedAuditUrlTask.result?.data.url}`,
    fileName: `grade_submissions_audit_log.csv`,
  })


  const pending  = useMemo(()=> {

    return (getPresignedUrlTask.pending && getPresignedUrlTask.started) || (getPresignedUrlTask.result !=null && downloadCentreProgress.pending && downloadCentreProgress.started)

  },[getPresignedUrlTask.pending, getPresignedUrlTask.started,  getPresignedUrlTask.result, downloadCentreProgress.pending,downloadCentreProgress.started])

  const auditDownloadPending  = useMemo(()=> {

    return (getPresignedAuditUrlTask.pending && getPresignedAuditUrlTask.started) || (getPresignedAuditUrlTask.result !=null && downloadAuditLogs.pending && downloadAuditLogs.started)

  },[getPresignedAuditUrlTask.pending, getPresignedAuditUrlTask.started,  getPresignedAuditUrlTask.result, downloadAuditLogs.pending,downloadAuditLogs.started])


  useAsyncRun(getPresignedUrlTask.result && downloadCentreProgress)
  useAsyncRun(getPresignedAuditUrlTask.result && downloadAuditLogs)

  const [searchInput, setSearchInput] = useState('')
  

  return (
    <>
      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row>
            <Col xs={24} md={10}>
              <h3 className={`h5 mb-1 font-weight-bold text-primary`}>
                Internal Dashboard
              </h3>
              <div>
                You can search{' '}
                <b>
                  <u>for a specific centre</u>
                </b>{' '}
                by it’s centre number and go to their dashboard from this page.
              </div>
            </Col>

            <Col className="text-right">
              <Button
                disabled={
                  auditDownloadPending
                }
                color="success"
                onClick={() =>{
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Audit Log'
                  })
                  getPresignedAuditUrlTask.start()
                  }}
              >
                {!(auditDownloadPending) && (
                  <FontAwesomeIcon icon={faArrowToBottom} className="mr-2" />
                )}
                {auditDownloadPending && (
                  <Spinner size="sm" className="mr-2" />
                )}
                <div>Download Submission Audit Log</div>
              </Button>
            </Col>
            <Col xs="auto" className="text-right">
              <Button
                disabled={pending}
                color="success"
                onClick={() => {
                  ReactGA.event({
                    category: 'CA Support',
                    action: 'Download Progress'
                  })
                  getPresignedUrlTask.start()
                }}
              >
                {!(pending
                ) && (
                  <FontAwesomeIcon icon={faArrowToBottom} className="mr-2" />
                )}
                {pending && (
                    <Spinner size="sm" className="mr-2" />
                  )}
                <div>Download Centre Progress</div>
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 align-items-center">
            <Col md={12}>
              <SearchBar
                val={searchInput}
                placeholder="Search for a centre"
                filterChanged={setSearchInput}
                onClick={() => history.push(`/centres/${searchInput}`)}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
