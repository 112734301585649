import React from 'react'
import {
  Container,
  Navbar,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
} from 'reactstrap'
import { Link, NavLink } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { useAuth0 } from '../auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPowerOff,
  faUsers,
  faUserShield,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons'

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { isAdmin, isCambridgeInternational, isOCR } from '../util'
import { PathParam } from '../types'
export const NavBar = (): JSX.Element => {
  
  const { logout, isAuthenticated, user, currentCentre } = useAuth0()
  const { location } = useReactRouter<PathParam>()

  const isLandingPage = location.pathname === '/'

  return (
    <div className="nav-container">
      <Navbar color="faded" className="border-bottom py-3" light expand="md">
        <Container>
          <Row className="w-100 align-items-center no-gutters">
            {!isLandingPage && (
              <Col>
                <Link to={`/`} onClick={() => sessionStorage.clear()}>
                  <span>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="mr-2 text-secondary"

                    />
                    <span className="font-weight-bold text-secondary">
                      Back
                      <span className="d-none d-md-inline"> to dashboard</span>
                    </span>
                  </span>
                </Link>
              </Col>
            )}
            <Col
              className={`${
                !isLandingPage ? 'navbar-logo-container--sub-page' : ''
              } navbar-logo-container font-weight-bold text-black text-left`}
            >
              {isCambridgeInternational() && (
                <img
                  src="/ci-logo.svg"
                  height="36"
                  alt="Cambridge Assessment International Education"
                />
              )}
              {isOCR() && <img src="/ocr-logo.svg" height="36" alt="OCR" />}
              {isLandingPage && (
                <div className="d-none d-lg-block site-heading">
                  Grade Submission System
                </div>
              )}
            </Col>

            <Col className="text-right">
              <Nav
                className="ml-auto align-items-end align-items-md-center  justify-content-end"
                navbar
              >
                {isAuthenticated && user && (
                  <>
                    <NavItem className="d-none d-md-block mr-lg-3">
                      <NavLink
                        className="font-weight-bold text-secondary"
                        to="/help-and-contact"
                      >
                        Help &amp; Contact
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        nav
                        className="ml-3 p-0 nav-toggle"
                        data-testid="header-toggle"
                      >
                        <div className="d-inline-block align-middle">
                          <div className="font-weight-bold  text-color333">
                            {user.roles.join(' | ')}
                          </div>
                          {currentCentre && (
                            <div className="text-secondary ">
                              {`Centre: ${currentCentre}`}
                            </div>
                          )}
                        </div>
                        <FontAwesomeIcon
                          className="d-inline-block ml-3 align-middle text-color333"
                          icon={faCaretDown}
                        />
                      </DropdownToggle>

                      <DropdownMenu right className="mt-3">
                        {isAdmin(user) && (
                          <>
                            <Link className="no-text-hover" to="/manage/users">
                              <DropdownItem
                                id="qaManageBtn"
                                className="text-left py-3 font-weight-bold mr-4 "
                              >
                                <FontAwesomeIcon
                                  icon={faUsers}
                                  className="mr-3"
                                />
                                Manage Users
                              </DropdownItem>
                            </Link>

                            <Link
                              className="no-text-hover"
                              to="/manage/signoff"
                            >
                              <DropdownItem
                                id="qaManageBtn"
                                className="text-left py-3 font-weight-bold mr-4 "
                              >
                                <FontAwesomeIcon
                                  icon={faUserShield}
                                  className="mr-3"
                                />
                                Manage Head of Centre
                              </DropdownItem>
                            </Link>

                            <div className="separator" />
                          </>
                        )}
                        <DropdownItem
                          id="qsLogoutBtn"
                          className="text-left text-redonblack py-3 font-weight-bold mr-4 "
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                        >
                          <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}
