import { Button, Label, ModalProps, Progress } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import axios, { AxiosResponse, AxiosError } from 'axios'
import React, { FC, useCallback, useState, useMemo } from 'react'
import ReactGA from 'react-ga'
import { CentreSyllabus, UploadStatus, PreSignedUploadUrl } from '../../types'
import { ProjectModal } from '../modal/modal'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadStarted } from './subcomponents/upload-started'
import { UploadFinished } from './subcomponents/upload-finished'
import { UploadFailed } from './subcomponents/upload-failed'
import { RsModalFooter } from '../modal/subcomponents/modal-footer'
import { Title } from '../title/title'
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons'
import {
  useAsyncTaskAxios,
  useAsyncRun,
  useAsyncTaskTimeout,
} from 'react-hooks-async'
import { isNotFound } from '../axios-error-helpers'
import { isCambridgeInternational, isOCR } from '../../util'
import { InlineErrorMessageNoBorder } from '../simple-message/inline-error-message'
import getTextFromToken from '../../tokenised-text'

const MAX_RETRIES = 9
const POLL_INTERVAL_SECS = 4
const TICK_INTERVAL_SECS = 0.5
const EXPECTED_TIME_TO_PROCESS = 20

export const ManageUploadModal: FC<
  ModalProps & CentreSyllabus & { onUploadComplete: (val: boolean) => void }
> = ({
  toggle,
  onUploadComplete,
  id,
  syllabusCode,
  syllabusName,
  qualLevel,
  totalCandidates,
  centreId,
  isOpen,
  qualification,
}) => {
  const [loadedValue, setLoadedValue] = useState(0)
  const [loadedMax, setLoadedMax] = useState(0)
  const [pollRetries, setPollRetries] = useState(0)
  const [toUpload, setToUpload] = useState<File | null>(null)

  const presignedUrlMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/uploadUrl`,
    }
  }, [centreId, id])

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, presignedUrlMemo)

  const uploadProgressMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/uploads/${getPresignedUrlTask.result?.data.uuid}`
    }
  }, [id, centreId, getPresignedUrlTask.result, pollRetries])

  const getUploadProgressTask = useAsyncTaskAxios<AxiosResponse<UploadStatus>>(
    axios,
    uploadProgressMemo
  )

  const getProgressNotFound = useMemo(() => {
    if (!getUploadProgressTask.error) {
      return false
    }
    return isNotFound((getUploadProgressTask.error as AxiosError).response)
  }, [getUploadProgressTask.error])

  const getProgressProcessing = useMemo(
    () =>
      getUploadProgressTask.result &&
      getUploadProgressTask.result.data.status === 'PROCESSING',
    [getUploadProgressTask.result]
  )

  const getUploadProgressTimedOut = useMemo(() => {
    return (
      (getProgressNotFound || getProgressProcessing) &&
      pollRetries === MAX_RETRIES
    )
  }, [pollRetries, getProgressNotFound, getProgressProcessing])

  const getProgressError = useMemo(() => {
    if (!getUploadProgressTask.error) {
      return false
    }
    return (
      !isNotFound((getUploadProgressTask.error as AxiosError).response) ||
      getUploadProgressTimedOut
    )
  }, [getUploadProgressTask.error, getUploadProgressTimedOut])

  const uploadFileMemo = useMemo(() => {
    return {
      method: 'PUT',
      url: getPresignedUrlTask.result?.data.url,
      data: toUpload,
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent
        setLoadedMax(total * 2)
        setLoadedValue(loaded)
      },
    }
  }, [getPresignedUrlTask.result, toUpload])

  const uploadFileTask = useAsyncTaskAxios<AxiosResponse<any>>(
    axios,
    uploadFileMemo
  )
  useAsyncRun(getPresignedUrlTask.result && toUpload && uploadFileTask)

  useAsyncRun(pollRetries > 0 && uploadFileTask.result && getUploadProgressTask)

  const tickItOver = useCallback(() => {
    setLoadedValue(
      Math.min(
        loadedMax / 2 / (EXPECTED_TIME_TO_PROCESS / TICK_INTERVAL_SECS) +
          loadedValue,
        loadedMax
      )
    )
  }, [loadedMax, loadedValue])
  const pollForProgress = useCallback(() => {
    if (getUploadProgressTask.started && getUploadProgressTask.pending) {
      return
    }
    if (!getUploadProgressTask.started && pollRetries === 0) {
      setPollRetries(1)
    }
    if (
      (getProgressNotFound ||
        (getUploadProgressTask.result &&
          getUploadProgressTask.result.data.status === 'PROCESSING')) &&
      pollRetries < MAX_RETRIES
    ) {
      setPollRetries(pollRetries + 1)
    }
  }, [
    uploadFileTask.result,
    pollRetries,
    getUploadProgressTask.started,
    getUploadProgressTask.result,
    getProgressNotFound,
    getUploadProgressTask.pending,
  ])

  const timer = useAsyncTaskTimeout(pollForProgress, POLL_INTERVAL_SECS * 1000)
  const tick = useAsyncTaskTimeout(tickItOver, TICK_INTERVAL_SECS * 1000)

  useAsyncRun(uploadFileTask.result && timer)
  useAsyncRun(uploadFileTask.result && tick)

  const pending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (uploadFileTask.pending && uploadFileTask.started) ||
      (uploadFileTask.result &&
        (!(getUploadProgressTask.result || getProgressError) ||
          (getProgressProcessing && !getUploadProgressTimedOut)))
    )
  }, [
    getPresignedUrlTask,
    uploadFileTask,
    getUploadProgressTask,
    getProgressProcessing,
    getProgressError, 
    getUploadProgressTimedOut,
  ])

  const [lastUploadFileWasInvalid, setLastUploadFileWasInvalid] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      const csvFile = (acceptedFiles[0] && acceptedFiles[0].name && acceptedFiles[0].name.endsWith('csv')) ? acceptedFiles[0] : null
      const fileValid = csvFile && rejectedFiles.length === 0

      setLastUploadFileWasInvalid(!fileValid)
      if (fileValid) {
        ReactGA.event({
          category: 'Grade Submission',
          action: 'Upload',
          label: id
        })
        setToUpload(csvFile)
        getPresignedUrlTask.start()
      }
    },
    [setToUpload, getPresignedUrlTask, id]
  )

  const dropZone = useDropzone({ onDrop: onDrop, accept: '.csv' })

  const setSubjectPageError = () => {
    getUploadProgressTask.result?.data.status === 'ERRONEOUS'
      ? onUploadComplete(true)
      : onUploadComplete(false)
  }

  return (
    <ProjectModal centered isOpen={isOpen}>
      <RsModalHeader
        toggle={() => {
          if (pending) return
          if (getUploadProgressTask.result || getUploadProgressTask.error) {
            setSubjectPageError()
          } else {
            ;(toggle as any)()
          }
        }}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={syllabusName}
          subTitle={
            qualLevel
              ? `${qualification} - ${qualLevel} ${syllabusCode}`
              : `${qualification} ${syllabusCode}`
          }
          ancillery={`${totalCandidates} candidates`}
        />
      </RsModalHeader>
      <RsModalBody className="px-5">
        <div {...dropZone.getRootProps({})} data-test-id="dropzone">
          <input {...dropZone.getInputProps()} />
          {!pending &&
            !(getUploadProgressTask.result?.data.status === 'ERRONEOUS') && (
              <Label className="font-larger mb-1 modal-margin font-weight-bold">
                Upload a template
              </Label>
            )}
          {pending && (
            <UploadStarted fileName={dropZone.acceptedFiles[0].name} />
          )}
          {getUploadProgressTask.result && (
            <>
              {getUploadProgressTask.result.data.status === 'PROCESSED' && (
                <UploadFinished fileName={dropZone.acceptedFiles[0].name} />
              )}
              {getUploadProgressTask.result.data.status === 'ERRONEOUS' && (
                <UploadFailed
                  fileName={toUpload!.name}
                  errors={getUploadProgressTask.result.data.validationErrors}
                  expectedCandidates={totalCandidates}
                  updatedCandidatesCount={
                    getUploadProgressTask.result.data.updatedItems
                  }
                />
              )}
            </>
          )}

          {!pending &&
            !getUploadProgressTask.result &&
            !getUploadProgressTimedOut && (
              <div className="mt-0 modal-margin">
                <span>
                  {getTextFromToken('uploadMessage')}
                </span>
                <p style={{ textDecoration: 'underline' }} className="mt-4">
                  {isCambridgeInternational() &&
                    'Before uploading the template, make sure that no one is currently editing this page.'}
                  {isOCR() &&
                    'If multiple users are editing this data, please check before uploading.'}
                </p>
              </div>
            )}
              {lastUploadFileWasInvalid && (
            <InlineErrorMessageNoBorder className="mt-4" title="You have selected an invalid file format. Please selected a CSV file." />
          )}
          {getUploadProgressTimedOut && (
             <InlineErrorMessageNoBorder className="mt-4" title="Your template has failed to upload. Please check your data and try again. If this problem persists, please contact us" />
          )}
          {(getPresignedUrlTask.error||uploadFileTask.error||(!getUploadProgressTimedOut && getProgressError))  && (
             <InlineErrorMessageNoBorder className="mt-4" title="Failed to upload, please try again and if the problem persists contact your system administrator." />
          )}
        </div>
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        {pending && (
          <Progress
            className=" mt-3 progress-slim-2 rounded-top-3 w-100 mx-0"
            max={loadedMax || 0}
            value={loadedValue || 0}
          />
        )}

        {!pending &&
          !getUploadProgressTask.result &&
          !getUploadProgressTimedOut && (
            <Button
              data-test-id="modal-upload-btn"
              color="primary"
              className="ml-0"
              size="lg"
              onClick={() => dropZone.open()}
            >
              <FontAwesomeIcon
                color="white"
                icon={faArrowToTop}
                className="mr-2"
              />
              Select file to upload
            </Button>
          )}
        {!pending &&
          getUploadProgressTask.result &&
          !getUploadProgressTimedOut && (
            <Button
              color="primary"
              size="lg"
              className="ml-0"
              onClick={setSubjectPageError}
            >
              Continue
            </Button>
          )}
        {getUploadProgressTimedOut && (
          <Button
            color="primary"
            size="lg"
            className="ml-0"
            onClick={setSubjectPageError}
          >
            Go back
          </Button>
        )}
      </RsModalFooter>
    </ProjectModal>
  )
}
