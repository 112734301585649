import React from 'react'

import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { MessagePropsBasic } from './simple-message'

export interface DismissableMessageProps extends ErrorMessageProps {
  close?: () => void
}
export interface ErrorMessageProps extends MessagePropsBasic {
  isBgDanger?: boolean
  allowPageRefresh?: boolean
}

export const InlineErrorMessage: React.FC<DismissableMessageProps> = ({
  title,
  message,
  close,
  className,
}): JSX.Element => (
  <div className={`p-35 bg-light rounded bs-danger ${className}`}>
    <Row className="align-items-center">
      <Col xs="auto">
        <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />
      </Col>
      <Col className="text-left ">
        <div className="text-danger font-weight-bold">{title}</div>
        <p className="mb-0">{message}</p>
      </Col>
      <Col xs="auto" className="text-right ">
        {!!close && (
          <Button className="text-danger" close onClick={() => close()} />
        )}
      </Col>
    </Row>
  </div>
)


export const InlineErrorMessageNoBorder: React.FC<ErrorMessageProps> = ({
  title,
  message,
  isBgDanger,
  className,
}): JSX.Element => (
  
    <Row className={`align-items-center ${isBgDanger ? 'text-white' : 'text-danger'} ${className}`}>
      <Col xs="auto">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </Col>
      <Col className="text-left ">
        <div className="font-weight-bold">{title}</div>
        {message && <p className="mb-0">{message}</p>}
      </Col>
    </Row>
)
