import React from 'react'
import { Col, Label, Row, Spinner } from 'reactstrap'
import { SyllabusCandidate } from '../../../types'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADES,
} from '../constants'
import { RankSelector } from '../utilities/rank-selector'

interface ICandidateFull {
  idx: number
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  isErroneous: boolean
  viewOnly: boolean
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
}

export const CandidateFull: React.FC<ICandidateFull> = ({
  idx,
  candidate: {
    id,
    centreSyllabusId,
    uniqueCandidateNumber,
    name,
    dob,
    grade,
    rank,
    allowedGrades,
    lastUpdated,
  },
  grading,
  isErroneous,
  viewOnly,
  candidatesInGrade,
  patchingProgress,
  onChange,
}): JSX.Element => {
  const sizes = candidateColumnSizes[5]

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-3'
          : 'candidate-row px-25 py-3'
      }
    >
      <Col className="text-left name" md={sizes[0]}>
        {name}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[1]}>
        {dob}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[2]}>
        {uniqueCandidateNumber}
      </Col>
      <Col className="text-center" md={sizes[3]}>
        <div className="candidate-grade px-3 py-25">{grade}</div>
      </Col>
      <Col className="text-center" md={sizes[4]}>
        {(!viewOnly && !(UNRANKED_GRADES.includes(grade || ''))) ? (
          <RankSelector
            tabIndex={idx}
            value={rank}
            max={candidatesInGrade}
            disabled={patchingProgress === 'pending'}
            onChange={(val: number, cb?: (good: boolean) => void) => {
              onChange({ grade: grade || '', rank: val }, cb)
            }}
          />
        ) : (
          <Label className="font-weight-bold">{rank || '–'}</Label>
        )}
        {patchingProgress === 'pending' &&
          <div style={{ position: 'absolute', right: '-8px', top: '5px' }}>
            <Spinner size="sm" color="secondary"/>
          </div>}
      </Col>
    </Row>
  )
}
