import React from 'react'
import { Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import getTextFromToken from '../tokenised-text'

const staticStyles = {
  height: '2px',
  position: 'relative',
  top: '16px',
  margin: '0 auto',
}

const widthMap = {
  2: '50%',
  3: '70%',
  4: '75%',
  5: '80%',
  6: '85%',
  7: '85%',
  8: '90%',
  9: '90%',
}

export const FlowInfo: React.FC<{ descriptions?: string[] }> = ({
  descriptions,
}): JSX.Element => {
  let stepDescriptions: string[] = []

  if (process.env.REACT_APP_BU === 'ci') {
    stepDescriptions = [
      'Download qualification templates or enter data on screen',
      `Enter ${getTextFromToken('grades')} and rank order`,
      'Upload or check your data for errors',
      'Amend any errors and send for sign off',
      'Sign off and submit',
    ]
  } else if (process.env.REACT_APP_BU === 'ocr') {
    stepDescriptions = [
      'Download spreadsheet template or choose to enter manually',
      'Complete and upload spreadsheet or enter centre assessment grades and rank orders manually',
      'Amend any errors and send to Head of Centre for approval',
      'Head of Centre checks and submits',
    ]
  }

  return (
    <div className="px-4 py-6 border border-2 rounded mb-5 border-eee">
      <div
        className="bg-primary"
        // @ts-ignore
        style={{
          ...staticStyles,
          // @ts-ignore
          width: widthMap[stepDescriptions.length] || '100%',
        }}
      />
      <Row>
        {stepDescriptions.map((e, idx) => (
          <Col key={idx} className="px-0">
            <div id={`div-circle-${idx}`} className="div-circle">
              {idx + 1 < stepDescriptions.length ? (
                idx + 1
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </div>
            <p className="mt-4 mx-3 mb-0 font-weight-bold text-center">{`${e}`}</p>
          </Col>
        ))}
      </Row>
    </div>
  )
}
