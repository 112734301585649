import React from 'react'

interface BackBoxProps {
  className?: string
}

export const BackBox: React.FC<BackBoxProps> = ({
  children,
  className,
}): JSX.Element => (
  <div className={`bg-light p-3 px-4 px-sm-5 py-md-4 rounded ${className}`}>
    {children}
  </div>
)
