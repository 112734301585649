import React, { FC } from 'react'
import { Router } from 'react-router-dom'
import { Route } from 'react-router'
import { AppState } from './types'
import { Auth0Provider } from './auth'
import { Loading } from './components/loading'
import { useConfig } from './components/use-remote-config'
import { RoutesWithHeader } from './components/routes-with-header'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import history from './history'
import getTextFromToken from './tokenised-text'

const onRedirectCallback = (appState: AppState): void => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const AppWithConfig: FC = ({ children }): JSX.Element => {
  const config = useConfig()
  if (config.loading) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }
  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      {...config.config.auth0Config!}
    >
      {children}
    </Auth0Provider>
  )
}
const App: FC = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>{getTextFromToken('title')}</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <link rel="icon" href={`/${getTextFromToken('favicon')}`} />
      </Helmet>
      <AppWithConfig>
        <Router history={history}>
          <Route component={RoutesWithHeader} />
        </Router>
      </AppWithConfig>
    </>
  )
}

export default App
