import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Tab } from '../tabs/subcomponents/tab'
import { TabList } from '../tabs/tab-list'
import { useAuth0 } from '../../auth'
import { isCAAdmin, ROLES } from '../../util'
import { faUserCog } from '@fortawesome/pro-solid-svg-icons'

interface ITab {
  to: string
  label: string
  icon: IconProp
  active: boolean
}

const NavTab: React.FC<ITab> = ({ to, label, icon, active }) => (
  <Tab autoSize to={to}>
    <div
      data-testid={`tab${to.replace(/\//g, '-')}`}
      className={`${
        active ? 'bg-primary text-white' : 'bg-lightButNotTooLight text-dark'
      } rounded font-weight-bold py-25 px-35 position-relative`}
    >
      <div data-test-id="syllabus-filter" className="text-center">
        <div className="font-larger">{label}</div>
        <div
          className={`${
            active ? '' : 'text-secondary'
          } h5 mb-0 font-weight-bold`}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
    </div>
  </Tab>
)

interface INavTabs {
  centreId: string
  activeRole: string
}

export const NavTabs: React.FC<INavTabs> = ({
  activeRole,
  centreId,
}): JSX.Element => {
  const { user } = useAuth0()
  return (
    <TabList className="mb-6">
      <NavTab
        to={
          user && isCAAdmin(user)
            ? `/centres/${centreId}/manage/users`
            : '/manage/users'
        }
        label="Manage Users"
        icon={faUsers}
        active={activeRole === ROLES.Teacher}
      />
      <NavTab
        to={
          user && isCAAdmin(user)
            ? `/centres/${centreId}/manage/signoff`
            : `/manage/signoff`
        }
        label="Manage Head of Centre"
        icon={faUserShield}
        active={activeRole === ROLES.HOC}
      />
      
    </TabList>
  )
}
