import React from 'react'
import useReactRouter from 'use-react-router'
import { ROLES } from '../../util'
import { ManagementPage } from './management-page'
import { PathParam } from '../../types'
import { Redirect } from 'react-router-dom'
import getTextFromToken from '../../tokenised-text'

const INFO_DESCRIPTION = (
  <>
    Please enter your staff members’ details. Users will receive a confirmation
    email with a verification link. Users must click the link to gain access to
    the system. Users will not be able to enter or upload{' '}
    <b>{getTextFromToken('grades').toLowerCase()} or rank orders</b> until they
    have done this.
  </>
)

export const ManageUsersPageWithRoute: React.FC = (): JSX.Element => {
  const { match } = useReactRouter<PathParam>()
  if (match.params.id !== match.params.id.toUpperCase()) {
    return (
      <Redirect to={`/centres/${match.params.id.toUpperCase()}/manage/users`} />
    )
  }
  return <ManageUsersPage centreId={match.params.id} />
}

export const ManageUsersPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Add new users"
    description={INFO_DESCRIPTION}
    buttonText="Add user"
    emptyListMessage="There are currently no users at this centre"
    role={ROLES.Teacher}
    currentUsersLabel="Current users"
  />
)
