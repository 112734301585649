import React from 'react'
import { faUserShield, faUsers, faUserCog } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, Container, Nav } from 'reactstrap'

import { Link } from 'react-router-dom'
import { useAuth0 } from '../auth'
import { isCAAdmin } from '../util'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export const CAdminBanner: React.FC<{
  centreId: string
  showBanner?: boolean
  linkBack?: boolean
}> = ({ centreId, showBanner, linkBack }): JSX.Element => {
  const { user } = useAuth0()
  if (user && isCAAdmin(user) && showBanner) {
    return (
      <Nav className={`font-weight-bold py-3 text-white bg-primary`}>
        <Container>
          <Row className="align-items-center">
            <Col>
              {linkBack && (
                <Link className="text-white" to={`/centres/${centreId}`}>
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-3" />
                  Centre {centreId}
                </Link>
              )}

              {!linkBack && <>Centre {centreId}</>}
            </Col>
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/users`}
                color="white"
              >
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                Manage users
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/signOff`}
                color="white"
              >
                <FontAwesomeIcon icon={faUserShield} className="mr-2" />
                Manage Head of Centre
              </Button>
            </Col>
            {user && isCAAdmin(user ) && (
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/admins`}
                color="white"
              >
                <FontAwesomeIcon icon={faUserCog} className="mr-2" />
                Manage Centre Admins
              </Button>
            </Col>
            )}
          </Row>
        </Container>
      </Nav>
    )
  } else return <></>
}
