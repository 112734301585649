import React from 'react'
import { MissingData } from '../../../types'
import getTextFromToken from '../../../tokenised-text'



export const CandidateErrorInfo: React.FC<MissingData> = ({missingPredicted,missingRank, tiedRank}) => {
  return (
    <div className="border rounded border-danger px-4 py-3">
      {missingPredicted > 0 && (<li className=" text-danger font-weight-bold">{`${missingPredicted} candidates with missing ${getTextFromToken('grades').toLowerCase()}`}</li>)}
      {missingRank > 0 && (<li className=" text-danger font-weight-bold">{`${missingRank} graded candidates with missing rank order`}</li>)}
      {tiedRank > 0 && (<li className=" text-danger font-weight-bold">{`${tiedRank} candidates with tied rank`}</li>)}
    </div>
  )
}