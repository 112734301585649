import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText, Button } from 'reactstrap'

import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SearchBar: React.FC<{
  val: string
  placeholder: string
  filterChanged: (val: string) => void
  onClick?: () => void
}> = ({ val, placeholder, onClick, filterChanged }): JSX.Element => {
  return (
    <div className="search-bar">
      <InputGroup>
        <input
          className="px-3 form-control"
          onChange={(e) => filterChanged(e.target.value)}
          value={val}
          placeholder={placeholder}
          onKeyPress={
            onClick
              ? (event) => {
                  if (event.key === 'Enter' || event.charCode === 13) {
                    event.preventDefault()
                    onClick()
                  }
                }
              : undefined
          }
          type="text"
        />
        {val.length > 0 && (
          <span className="delete-icon" onClick={() => filterChanged('')}>
            <FontAwesomeIcon className="text-secondary" icon={faTimes} />
          </span>
        )}
        <InputGroupAddon addonType="append">
          {onClick && (
            <Button onClick={onClick} color="primary" disabled={val.length < 5}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          )}
          {!onClick && (
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          )}
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}
