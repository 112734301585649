import React, { useState } from 'react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Checkbox: React.FC<{
  value: boolean
  toggle: (force?: boolean) => void
  className?: string
  variant?: 'yes-no'
}> = ({ value, toggle, className, variant }): JSX.Element => {
  const [touched, setTouched] = useState(false);
  if (variant === "yes-no") {
    return (
      <div className={`checkbox-explicit ${touched ? "touched" : "untouched"}`}>
        <span
          className={`yes ${value ? "active" : ""}`}
          onClick={() => {
            setTouched(true);
            toggle(true);
          }}
        >
          Yes
        </span>
        <span
          className={`no ${!value ? "active" : ""}`}
          onClick={() => {
            setTouched(true);
            toggle(false);
          }}
        >
          No
        </span>
      </div>
    )
  } else {
    return (
      <div
        className={`checkbox-internal ${className} ${value ? 'selected' : ''}`}
        onClick={() => toggle()}
      >
        {value ? <FontAwesomeIcon icon={faCheck} fixedWidth /> : ''}
      </div>
    )
  }
}
