import React, { FC } from "react";
import { Col, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

export interface TabProps {
  to: string;
  disabled?: boolean;
  autoSize?: boolean;
}

export const Tab: FC<TabProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  to,
  disabled,
  autoSize,
  className,
}): JSX.Element => {
  return (
    <Col xs={autoSize ? "auto" : undefined} className={className || ""}>
      <NavLink
        tag={disabled ? undefined : Link}
        className="p-0"
        to={disabled ? undefined : to}
      >
        {children}
      </NavLink>
    </Col>
  );
};
