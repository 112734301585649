import React, {useMemo, useState} from 'react'
import querystring from 'querystring'
import useReactRouter from 'use-react-router'
import {countBy, keys} from 'lodash'
import axios, {AxiosError, AxiosResponse} from 'axios'
import {useAsyncRun, useAsyncTaskAxios} from 'react-hooks-async'
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Nav,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
import {TabList} from '../tabs/tab-list'
import {Tab} from '../tabs/subcomponents/tab'
import {TabContentLabelWithCount} from '../tab-content-label-with-count'
import {CentreSyllabus, PathParam, ValuationStatus} from '../../types'
import {SyllabusProgress} from '../syllabus-progress/syllabus-progress'

import {Loading} from '../loading'
import {isForbidden} from '../axios-error-helpers'
import {SimpleErrorMessage} from '../simple-message/simple-error-message'
import {SimpleMessage} from '../simple-message/simple-message'
import {SearchBar} from '../search-bar/search-bar'
import {formatDate} from '../candidates-list/constants'
import {isCAAdmin, isCambridgeInternational, isHOC, isOCR, syllabusInProgress} from '../../util'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarCheck, faCheck, faClock, faSortDown} from '@fortawesome/pro-solid-svg-icons'
import {faBell} from '@fortawesome/pro-regular-svg-icons'
import {useAuth0} from '../../auth'
import ReactMarkdown from 'react-markdown'
import {useConfig} from '../use-remote-config'

import ciIntroContent from './ciIntroContent.md'
import ocrIntroContent from './ocrIntroContent.md'
import getTextFromToken from '../../tokenised-text'
import {NotAvailable} from '../not-available'
import {Redirect} from 'react-router-dom'
import {CAdminBanner} from '../ca-admin-banner'
import {format} from 'date-fns'

const VALUATION_ORDER = [
  '---',
  ValuationStatus.SUBMITTED,
  ValuationStatus.COMPLETE,
  ValuationStatus.INCOMPLETE,
  ValuationStatus.NOT_STARTED,
  ValuationStatus.APPROVED,
]

const SORT_SYLLABUS = (a: CentreSyllabus, b: CentreSyllabus) =>
  a.syllabusName.localeCompare(b.syllabusName) ||
  a.syllabusCode.localeCompare(b.syllabusCode) ||
  (VALUATION_ORDER.indexOf(a.valuationStatus) || 100) -
    (VALUATION_ORDER.indexOf(b.valuationStatus) || 100) ||
  a.qualification.localeCompare(b.qualification)

const STATUS_FILTERS: { [key: string]: string } = {
  ALL: 'All',
  [ValuationStatus.NOT_STARTED]: 'Not Started',
  [ValuationStatus.INCOMPLETE]: 'In Progress',
  [ValuationStatus.COMPLETE]: 'Ready for Approval',
  [ValuationStatus.SUBMITTED]: 'Awaiting Approval',
  [ValuationStatus.APPROVED]: 'Approved and Submitted',
  [ValuationStatus.OPENED]: 'All Opened'
}
export const CentreSyllabusPageWithRoute: React.FC = (): JSX.Element => {
  const { match } = useReactRouter<PathParam>()
  const { user } = useAuth0()
  if (user && !isCAAdmin(user)) {
    return <Redirect to="/" />
  }
  if (match.params.id !== match.params.id.toUpperCase()) {
    return <Redirect to={`/centres/${match.params.id.toUpperCase()}`} />
  }
  return <CentreSyllabusPage centreId={match.params.id} />
}

export const CentreSyllabusPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => {
  const mockBoolean = false
  const { config } = useConfig()

  const [searchFilter, setSearchFilter] = useState('')
  const [showReopenedOnly, setShowReopenedOnly] = useState(true)
  const { location } = useReactRouter()
  const { user } = useAuth0()
  const getCentreSyllabusMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses`,
    }
  }, [centreId])

  const getCentreSyllabusTask = useAsyncTaskAxios<
    AxiosResponse<CentreSyllabus[]>
  >(axios, getCentreSyllabusMemo)

  useAsyncRun(getCentreSyllabusTask)

  const reopenedCount = useMemo(() => {
    return getCentreSyllabusTask.result ?
      getCentreSyllabusTask.result?.data.filter((x) => !x.closed && (syllabusInProgress(x.valuationStatus) || x.valuationStatus === ValuationStatus.SUBMITTED)).length : 0
  }, [getCentreSyllabusTask.result])

  const submittingClosed = useMemo(() => {
    return getCentreSyllabusTask.result?.data.some(e => e.closed)
  }, [getCentreSyllabusTask.result])

  const qualificationFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.qualification) {
      return 'All Quals'
    }

    if (Array.isArray(qs.qualification)) {
      return qs.qualification[0]
    }

    return qs.qualification
  }, [location])

  const forbidden = useMemo(() => {
    if (!getCentreSyllabusTask.error) {
      return false
    }
    return isForbidden((getCentreSyllabusTask.error as AxiosError).response)
  }, [getCentreSyllabusTask.error])

  const centres = useMemo(() => {
    return getCentreSyllabusTask.result ? getCentreSyllabusTask.result.data : []
  }, [getCentreSyllabusTask.result])

  const qualCounts = useMemo(() => {
    return countBy(centres, 'qualification')
  }, [centres])

  const notificationMessage = useMemo<any>(() => {
    if (user && isHOC(user)) {
      const submissionCount = (centres || []).filter(
        (x) => x.valuationStatus === ValuationStatus.SUBMITTED && !x.closed
      ).length
      if (submissionCount > 0) {
        const syllabusWord =
          submissionCount === 1
            ? getTextFromToken('syllabus')
            : getTextFromToken('syllabuses')
        return {
          icon: faBell,
          message: `You have ${submissionCount} ${syllabusWord} waiting for your approval`,
          className: 'text-white bg-success',
        }
      }
    }
    return undefined
  }, [user, centres])

  const uniqueQuals = useMemo(() => {
    return keys(qualCounts).sort()
  }, [qualCounts])

  const filtersCount = useMemo<{ [key: string]: number }>(
    () =>
      centres.reduce(
        (acc, curr) => ({
          ...acc,
          // @ts-ignore
          [curr.valuationStatus]: (acc[curr.valuationStatus] || 0) + 1,
          // @ts-ignore
          ALL: (acc.ALL || 0) + 1,
        }),
        {}
      ),
    [centres]
  )

  const [statusFilter, setStatusFilter] = useState('ALL')

  const filtered = useMemo(() => {
    let filtered = centres
      .filter(
        (x) =>
          qualificationFilter === 'All Quals' ||
          x.qualification === qualificationFilter
      )
      .filter(
        (x) => statusFilter === 'ALL' || x.valuationStatus === statusFilter || (statusFilter === ValuationStatus.OPENED && !x.closed && x.valuationStatus !== ValuationStatus.APPROVED)
      )

    if (searchFilter.length > 0) {
      filtered = filtered.filter(
        (x) =>
          (x.syllabusName &&
            x.syllabusName
              .toLowerCase()
              .includes(searchFilter.toLowerCase())) ||
          (x.syllabusCode && x.syllabusCode.toLowerCase().includes(searchFilter.toLowerCase()))
      )
    }
    return filtered
  }, [centres, qualificationFilter, searchFilter, statusFilter])

  const sorted = useMemo(() => {
    const list = [...filtered]
    list.sort(SORT_SYLLABUS)
    return list
  }, [filtered])

  const latestDate = useMemo(
    () =>
      centres.reduce((acc, curr) => Math.max(acc, curr.lastUpdated || 0), 0),
    [centres]
  )

  const lastUpdatedString = useMemo(
    () => formatDate(new Date(latestDate), true, true).replace(/\//g, ' / '),
    [latestDate]
  )

  if (user && !isCAAdmin(user) && Date.now() < Number(config.available)) {
    return (
      <>
        <NotAvailable />
      </>
    )
  }

  return (
    <>
      <CAdminBanner centreId={centreId} showBanner />

      {notificationMessage && (
        <Nav
          className={`font-weight-bold py-3 ${notificationMessage.className}`}
        >
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              {notificationMessage.icon && (
                <FontAwesomeIcon
                  icon={notificationMessage.icon}
                  className="mr-3"
                />
              )}
              {notificationMessage.message}
            </span>
          </Container>
        </Nav>
      )}
      {submittingClosed && reopenedCount > 0 && (
        <Nav
          className="error-nav bg-primary text-white font-weight-bold py-3 top-border"
        >
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
                <FontAwesomeIcon
                  icon={faBell}
                  className="mr-3"
                />
              {`You have ${reopenedCount} ${reopenedCount > 1 ? getTextFromToken("syllabuses") : getTextFromToken("syllabus")} that ${reopenedCount > 1 ? "are" : "is"} open for submission`}
            </span>
            {showReopenedOnly && (
              <Container className="w-50 text-right px-0 mx-0">
                <Button
                  color="white"
                  className="text-primary"
                  onClick={() => {
                    setStatusFilter(ValuationStatus.OPENED)
                    setShowReopenedOnly(false)
                  }}
                >{`Show ${reopenedCount > 1 ? "these" : "that"} ${reopenedCount > 1 ? getTextFromToken("syllabuses") : getTextFromToken("syllabus")}`}</Button>
              </Container>
            )}
          </Container>
        </Nav>
      )}

      {submittingClosed && (
        <Nav className="error-nav bg-success text-white font-weight-bold py-4">
          <Container>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              fixedWidth
              className="mr-3"
            />
            {isCambridgeInternational() && (
              <>
                <>The deadline for submitting grades and rank orders <u>has now passed.</u></>
                <div className="mt-2 cust-margin">You can no longer submit data into the system. You can only view and download.  If you have any queries or questions please contact us. Thank you.</div>
              </>)}
            {isOCR() && (
              <>
                <>The deadline for submitting some qualifications <u>has passed.</u></>
                <ul className="mt-3 ml-4">
                  <li>You can only submit data for the qualifications that are still open for centre assessment grades and rank orders.</li>
                  <li>For others you can view and download. If you have any queries please contact us. Thank you.</li>
                </ul>
              </>)}
          </Container>
        </Nav>
      )}

      <div className="bg-light py-45 px-3 mb-5">
        <Container>
          <Row className="mb-1">
            <Col lg={12}>
              <div className="markdown-content">
                {isCambridgeInternational() && (
                  <ReactMarkdown source={ciIntroContent} />
                )}
                {isOCR() && <ReactMarkdown source={ocrIntroContent} />}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="list-inline font-weight-bold ">
                <li className="list-inline-item mr-5">
                  <span className="mr-2" >&bull;</span> Download or upload templates
                </li>
                <li className="list-inline-item mr-5">
                <span className="mr-2" >&bull;</span> Enter or edit grades and rank order
                </li>
                <li className="list-inline-item mr-3">
                  <span className="mr-2">&bull;</span> Send for review and
                  approval
                </li>
              </ul>
            </Col>
            {config.deadline && !submittingClosed &&(
              <Col xs="auto" className="text-secondary font-weight-bold text-right">
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                {`Deadline is ${format(new Date(config.deadline), 'MMMM do yyyy')}`}
              </Col>
            )
            }
          </Row>
        </Container>
      </div>

      <Container className="mt-5">
        {getCentreSyllabusTask.pending && (
          <SimpleMessage
            className="mb-5"
            icon={<Loading className="d-block mx-auto" />}
            title={`Retrieving ${getTextFromToken('syllabuses')}...`}
          />
        )}
        {forbidden && (
          <SimpleErrorMessage title="You do not have permission to submit for this centre" />
        )}
        {!forbidden && getCentreSyllabusTask.error && (
          <SimpleErrorMessage title={`Failed to load ${getTextFromToken('syllabuses')}, please refresh and if the problem persists contact your system administrator`} allowPageRefresh/>
        )}
      </Container>
      <Container className="mt-5">
        {getCentreSyllabusTask.result &&
          getCentreSyllabusTask.result.data.length > 0 && (
            <>
              <Row className="my-5">
                <Col lg={11}>
                  <SearchBar
                    val={searchFilter}
                    placeholder={`Search for a ${getTextFromToken('syllabus')}`}
                    filterChanged={(newVal) => setSearchFilter(newVal)}
                  />
                </Col>
                <Col className="d-flex justify-content-end opacity-59 font-weight-semi-bold text-right">
                  {!!latestDate && (
                    <Label>
                      <div>Last updated on</div>
                      <div>{lastUpdatedString}</div>
                    </Label>
                  )}
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs="auto" className="text-left font-weight-bold">
                  <TabList className="mt-n3">
                    <Tab
                      className="mt-3"
                      autoSize
                      to={`?qualification=${encodeURIComponent('All Quals')}`}
                    >
                      <TabContentLabelWithCount
                        label="All Quals"
                        isActive={qualificationFilter === 'All Quals'}
                        count={centres.length}
                        countPending={mockBoolean}
                      />
                    </Tab>
                    {uniqueQuals.map((qual, idx) => (
                      <Tab
                        className="mt-3"
                        autoSize
                        key={`qual-${idx}`}
                        to={`?qualification=${encodeURIComponent(qual)}`}
                      >
                        <TabContentLabelWithCount
                          label={qual}
                          isActive={qualificationFilter === qual}
                          count={qualCounts[qual]}
                          countPending={mockBoolean}
                        />
                      </Tab>
                    ))}
                  </TabList>
                </Col>
                <Col
                  style={{ minWidth: '280px' }}
                  className="d-inline-flex justify-content-end align-items-end"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle className="ml-3 p-0 label-dropdown d-inline-flex align-content-center">
                      Filtered by ({STATUS_FILTERS[statusFilter]})
                      <FontAwesomeIcon className="ml-2" icon={faSortDown} />
                    </DropdownToggle>

                    <DropdownMenu right className="mt-3">
                      {Object.keys(STATUS_FILTERS).map((key) => (
                        <DropdownItem
                          className={`text-left py-3 font-weight-bold mr-4
                        ${key === statusFilter ? 'bg-primary' : ''}`}
                          onClick={() => {
                            setStatusFilter(key)
                          }}
                          key={`filter-dropdown-${key}`}
                        >
                          {key === statusFilter && (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                          <span
                            className={key === statusFilter ? 'ml-2' : 'ml-4'}
                          >
                            {`${STATUS_FILTERS[key]} (${
                              key === "opened" ? reopenedCount : filtersCount[key] || 0
                            })`}
                          </span>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              {sorted.map((qual, idx) => (
                <SyllabusProgress
                  key={`sylprog-${idx}`}
                  className="mb-45"
                  {...qual}
                />
              ))}
            </>
          )}

        {user && getCentreSyllabusTask.result && sorted.length === 0 && (
          <SimpleMessage
            title={`No ${getTextFromToken('syllabuses')} ${
              centres.length === 0
                ? ' found for this centre'
                : ' match the filter/search'
            }`}
            message ={isCAAdmin(user) ? "Please check that you have entered the correct Centre ID.": undefined}
          />
        )}
      </Container>
    </>
  )
}
