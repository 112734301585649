import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'

import { SyllabusCandidate } from '../../types'
import { ProjectModal } from '../modal/modal'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import ReactGA from 'react-ga'

import { CandidatesList } from './candidates-list'
import { Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

interface ICandidatesListContainer {
  gradeFilter: string
  candidates: SyllabusCandidate[]
  centreId: string
  syllabusId: string
  lastUpdated: number
  allowTies?: boolean
  showErrors: boolean
  viewOnly: boolean
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (status: boolean) => void
  downloadCallback: () => void
}

export const CandidatesListContainer: React.FC<ICandidatesListContainer> = ({
  gradeFilter,
  showErrors,
  toggleShowErrors,
  candidates,
  centreId,
  syllabusId,
  lastUpdated,
  viewOnly,
  allowTies,
  approvalDisabled,
  downloadCallback,
}): JSX.Element => {
  const candidatesBaseUrl = useMemo(
    () =>
      `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    [centreId, syllabusId]
  )
  const [cachedCandidates, updateCachedCandidates] = useState<
    SyllabusCandidate[]
  >(candidates)
  const [lastUpdate, updateLastUpdate] = useState<number>(lastUpdated)
  const [candidatesPatchingProgress, setCandidatesPatchingProgress] = useState<{[key: string]: string|undefined}>({})
  const [changeRequest, setChangeRequest] = useState<any | null>(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    updateCachedCandidates(candidates)
  }, [candidates])

  const patchCandidate = useMemo(
    () => (
      update: { id: string; grade: string; rank?: number },
      cb?: (good: boolean) => void
    ) => {
      ;(async () => {
        const { id, grade, rank } = update
        if (candidatesPatchingProgress[id] === 'pending') return

        setCandidatesPatchingProgress(tasks => ({
          ...tasks,
          [id] : 'pending'
        }))

        const cachedGrade = (cachedCandidates.find((it) => it.id === id) || {}).grade
        try {
          ReactGA.event({
            category: 'Grade Submission',
            action: 'Patch Candidate',
            label: syllabusId
          })

          const { status, data } = await axios({
            method: 'patch',
            url: `${candidatesBaseUrl}/${encodeURIComponent(id)}`,
            data: {
              id,
              grade,
              rank:
                cachedGrade !== grade ? undefined : rank,
            },
          })

          if (status !== 200 && status !== 205) {
            throw new Error()
          }

          let updates = [data]
          if (status === 205) {
            const res = await axios.get(candidatesBaseUrl)
            if (res.status === 200) {
              updates = res.data
            }
          }

          updateCachedCandidates((cachedCandidates) =>
            cachedCandidates.map((it: any) => {
              const c = updates.find((itt: any) => itt.id === it.id)
              return !c
                ? it
                : {
                    ...it,
                    grade: c.grade,
                    rank: c.rank,
                  }
            })
          )

          updateLastUpdate(Date.now())
          cb && cb(true)
          setCandidatesPatchingProgress(tasks => ({
            ...tasks,
            [id]: undefined
          }))
        } catch (err) {
          setCandidatesPatchingProgress(tasks => ({
            ...tasks,
            [id]: `error-${cachedGrade !== grade ? 'g' : 'r'}`
          }))
          cb && cb(false)
        }
      })()
    },
    [
      candidatesBaseUrl,
      cachedCandidates,
      candidatesPatchingProgress,
      syllabusId
    ]
  )

  const onPatchRequest = useMemo(
    () => (
      update: { id: string; grade: string; rank?: number },
      cb?: (good: boolean) => void
    ) => {
      if (viewOnly) return
      const candidate = cachedCandidates.find((c) => c.id === update.id)
      if (!candidate) return
      if (
        candidate.grade &&
        candidate.grade !== update.grade &&
        candidate.rank
      ) {
        setChangeRequest({
          candidate,
          update,
          cb,
        })
        setShowModal(true)
      } else {
        patchCandidate(update, cb)
      }
    },
    [cachedCandidates, setChangeRequest, setShowModal, patchCandidate, viewOnly]
  )

  const clearPatchingErrors = useMemo(() => (ids?:string[]) => {
    setCandidatesPatchingProgress(x =>
      Object.entries(x).reduce((acc, [key, val = '']) => ({
        ...acc,
        ...(val.startsWith('error') && (!ids || ids.includes(key)) ? {} : {[key]: val})
      }), {}))
  }, [setCandidatesPatchingProgress])

  useEffect(() => {
    clearPatchingErrors()
  }, [clearPatchingErrors, gradeFilter])

  return (
    <div>
      <CandidatesList
        approvalDisabled={approvalDisabled}
        gradeFilter={gradeFilter}
        showErrors={showErrors}
        toggleShowErrors={toggleShowErrors}
        allowTies={allowTies}
        candidates={cachedCandidates}
        candidatesPatchingProgress={candidatesPatchingProgress}
        clearPatchingErrors={clearPatchingErrors}
        patchCandidate={onPatchRequest}
        downloadCallback={downloadCallback}
        lastUpdated={lastUpdate}
        upToDate={Object.values(candidatesPatchingProgress).filter(x => x === 'pending').length === 0}
        viewOnly={viewOnly}
      />

      <ProjectModal
        className="candidate-grade-change-modal"
        centered
        isOpen={showModal && changeRequest !== null}
      >
        <RsModalHeader
          toggle={() => setShowModal(false)}
          className="bg-white px-5 pt-5 pb-0"
        >
          <h4
            className="font-weight-bold text-primary mb-0"
            style={{ fontSize: '22px' }}
          >
            Changing grade for a candidate
          </h4>
          <h5 className="mb-0 mt-25">
            <span className="font-weight-bold">
              {changeRequest?.candidate?.name}{' '}
              {changeRequest?.candidate?.surname}
            </span>
            <span className="ml-1">{`(${changeRequest?.candidate.uniqueCandidateNumber})`}</span>
          </h5>
        </RsModalHeader>
        <RsModalBody className="pt-0 px-5 pb-5">
          <div className="mt-4">
            <Label
              className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem' }}
            >
              {changeRequest?.candidate?.grade}
            </Label>
            <FontAwesomeIcon
              className="mx-42 d-inline-flex align-items-center"
              style={{ width: '20px', height: '20px' }}
              size="2x"
              icon={faArrowRight}
            />
            <Label
              className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem' }}
            >
              {changeRequest?.update?.grade}
            </Label>
          </div>
          <Label className="mb-0 mt-4 font-larger" style={{ maxWidth: '90%' }}>
            You are trying to change this candidate's grade. This will remove
            their current rank order. You will need to provide a new rank order
            for the new grade.
          </Label>
          <Label
            className="mb-0 mt-4 font-larger font-weight-bold"
            style={{ maxWidth: '90%' }}
          >
            Please note the rank orders for candidates in this grade will also
            be updated based on their existing order.
          </Label>
          <div className="mt-55">
            <Label
              data-testid={'grade-change-cancel'}
              className="bg-lightButNotTooLight text-secondary font-weight-bold rounded px-35 py-25 mb-0 pointer"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Label>
            <Label
              data-testid={'grade-change-continue'}
              className="bg-primary text-white font-weight-bold rounded px-35 py-25 ml-3 mb-0 pointer"
              onClick={() => {
                setShowModal(false)
                setChangeRequest(null)
                patchCandidate(changeRequest?.update, changeRequest?.cb)
              }}
            >
              Continue
            </Label>
          </div>
        </RsModalBody>
      </ProjectModal>
    </div>
  )
}
